<template>
	<transition name="fade" mode="out-in">
		<Popup v-if="isExpanded">
			<template #modal-header-content>
				<div class="modal-title">
					<h3>Equipment count</h3>
				</div>
				<div class="btn-icon muted" @click="expand">
					close
					<fa icon="times" />
				</div>
			</template>
			<template #modal-body-content>
				<div
					class="tags-modal"
					v-if="nameCount && equipmentNames.length && !searchTag"
				>
					<div
						class="item"
						v-for="name in equipmentNames"
						:key="name"
					>
						<div class="name">{{ name }}</div>
						<div class="number">{{ nameCount[name] }}</div>
					</div>
				</div>
			</template>
		</Popup>
	</transition>
	<div class="right-content">
		<EquipmentDashNav :access="access" />
		<div class="page-content">
			<div class="page-detail">
				<sub-nav-loading v-if="loading" />
				<!-- Sub Nav -->
				<div v-else class="right-sub-nav fixed">
					<div class="nav-row search-input">
						<div class="sub-nav-left">
							<div class="search">
								<fa class="search-icon" icon="search" />
								<input
									id="tag-search"
									type="search"
									placeholder="Search or filter by tags..."
									v-model="searchTag"
									@keyup.enter="addSearchTag"
								/>
							</div>
							<!-- Filter tags -->
							<div
								class="pill-container fade-row"
								v-if="searchTags.length"
							>
								<div
									v-for="searchTag in searchTags"
									:key="searchTag"
									class="pill bg-blue"
								>
									<fa class="fa-tag" icon="tag" />
									{{ searchTag }}
									<fa
										class="fa-times"
										icon="times"
										@click="deleteSearchTag(searchTag)"
									/>
								</div>
							</div>
						</div>
						<div class="sub-nav-right">
							<!-- Record count -->
							<div v-if="items && searchedItems">
								<b>
									{{ searchedItems.length }}
									<span v-if="searchedItems.length == 1"
										>record</span
									>
									<span v-if="searchedItems.length > 1"
										>records</span
									>
								</b>
							</div>

							<!-- Toggle between statuses -->
							<div
								class="status-toggle"
								@click="switchStatus"
								v-if="items && searchedItems"
							>
								<fa icon="angle-left" />
								<p>{{ statusText }}</p>
								<fa icon="angle-right" />
							</div>

							<!-- Select equipment -->
							<div
								v-if="access && showEdit"
								class="edit-container"
							>
								<div
									class="btn-link"
									v-on:click.prevent="bulkUpdate"
								>
									<p>
										Edit Selected &nbsp;
										<span>
											<fa icon="edit" />
										</span>
									</p>
								</div>
							</div>

							<!-- Add New Item -->
							<div v-if="access && !showEdit">
								<router-link
									:to="{
										name: 'EquipmentEdit',
										params: { id: 'new' },
									}"
									class="btn-link"
								>
									<!-- <fa class="add-icon" icon="plus-circle" /> -->
									<p>
										Add New &nbsp;
										<span>
											<fa icon="plus-circle" />
										</span>
									</p>
								</router-link>
							</div>
						</div>
					</div>

					<!-- List of clickable item names that adds item name to tags search -->
					<div class="nav-row">
						<div
							class="tags-container"
							v-if="items && searchedItems"
						>
							<div
								class="name-count fade-row"
								v-if="
									nameCount &&
									equipmentNames.length &&
									!searchTag
								"
							>
								<div v-for="name in equipmentNames" :key="name">
									<div
										class="item"
										@click="addNameFilter(name)"
									>
										<div class="name">{{ name }}</div>
										<div class="number">
											{{ nameCount[name] }}
										</div>
									</div>
								</div>
							</div>
							<fa
								icon="expand-alt"
								class="icon"
								@click="expand"
								v-if="
									nameCount &&
									equipmentNames.length &&
									!searchTag &&
									equipmentNames.length > 6
								"
							/>
						</div>
						<div
							class="select-all-container"
							v-if="
								access && renderedItems && renderedItems.length
							"
						>
							<label for="my-checkbox" class="custom-checkbox">
								<input
									type="checkbox"
									id="my-checkbox"
									v-model="selectAll"
								/>
								<span class="checkmark"></span>
							</label>
						</div>
					</div>
				</div>

				<!-- Loading -->
				<list-loading v-if="loading" />

				<!-- Items List -->
				<div v-else class="list-container">
					<!-- If items are empty -->
					<div
						class="no-list vertical-center"
						v-if="empty && !loading"
					>
						<div class="icon">
							<fa icon="tools" />
						</div>
						<p>No Equipment found</p>
						<div v-if="role != 'Admin'">
							<br />
							<p>
								Ensure that you have been granted access to the
								location where the equipment records are logged
							</p>
						</div>
					</div>

					<div class="table-row-container">
						<div
							v-for="(item, index) in renderedItems"
							:key="item.identifier"
						>
							<div
								class="table-row"
								v-if="index < renderedItemsCount"
							>
								<div class="row-left">
									<EquipmentRowLeft
										:key="item.identifier"
										:item="item"
										:canEdit="true"
									/>

									<div class="tags fade-row">
										<div
											v-for="tag in item.tags"
											:key="tag"
										>
											<div
												@click="addNameFilter(tag)"
												class="pill-hover"
												:class="
													tagSearched(tag) === true
														? 'pill bg-blue'
														: 'pill'
												"
											>
												<div class="content">
													<fa
														class="fa-tag"
														icon="tag"
													/>
													<p>{{ tag }}</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="row-right">
									<EquipmentRowRight
										:key="item.identifier"
										:item="item"
										:canEdit="true"
									/>

									<div class="check-container" v-if="access">
										<label
											:for="'my-checkbox-' + item.id"
											class="custom-checkbox"
										>
											<input
												type="checkbox"
												:id="'my-checkbox-' + item.id"
												v-model="item.isChecked"
											/>
											<span class="checkmark"></span>
										</label>
									</div>
								</div>
							</div>
						</div>
						<div
							class="center-btn p-1rem"
							@click="renderMore"
							v-if="showMore"
						>
							<div class="btn-icon">
								<div class="text">Show More</div>
								<fa icon="angle-down" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<router-view v-slot="{ Component }">
		<transition name="fade" mode="in-out">
			<component :is="Component" />
		</transition>
	</router-view>
</template>

<script>
import EquipmentDashNav from "../../components/equipment/EquipmentDashNav";

import {
	computed,
	ref,
	provide,
	onMounted,
	watch,
	watchEffect,
} from "@vue/runtime-core";
import account from "../../composables/account";
import { useMeta } from "vue-meta";
import ListLoading from "../../components/ListLoading.vue";
import SubNavLoading from "../../components/SubNavLoading.vue";
import Popup from "../../components/Popup.vue";
import { useRouter } from "vue-router";
import EquipmentRowLeft from "../../components/equipment/EquipmentRowLeft.vue";
import EquipmentRowRight from "../../components/equipment/EquipmentRowRight.vue";
import { useEquipmentStore } from "../../stores/equipmentStore";
import { storeToRefs } from "pinia";
import PageLoading from "../../components/PageLoading.vue";

export default {
	name: "EquipmentDash",
	components: {
		EquipmentDashNav,
		ListLoading,
		SubNavLoading,
		Popup,
		EquipmentRowLeft,
		EquipmentRowRight,
		PageLoading,
	},
	setup() {
		useMeta({ title: "Equipment" });
		const { companyId, userDoc, error, role } = account();
		const equipmentStore = useEquipmentStore();
		const { equipment: items, loading } = storeToRefs(equipmentStore);

		const searchQuery = ref("");
		const searchTag = ref("");
		const searchTags = ref([]);
		const selectAll = ref(false);
		const dataToUpdate = ref(null);
		const router = useRouter();

		const renderedItemsCount = ref(50);

		onMounted(() => {
			equipmentStore.fetchEquipment(companyId, false);
		});

		const showMore = ref(true);

		const renderMore = () => {
			searchedItems.value.length > renderedItemsCount.value
				? (renderedItemsCount.value += 50)
				: (renderedItemsCount.value = searchedItems.value.length);

			if (renderedItemsCount.value >= searchedItems.value.length) {
				showMore.value = false;
			}
		};

		const statusText = ref("Status (All)");

		const bulkData = ref(null);

		const showForm = (data) => {
			if (data.length > 0) {
				bulkData.value = data;
				router.push({
					name: "EquipmentEdit",
					params: {
						id: "bulk-update",
					},
				});
			}
		};

		provide("bulkDataAray", bulkData);

		const switchStatus = () => {
			if (statusText.value == "Status (All)") {
				statusText.value = "Safe for use";
				return;
			}
			if (statusText.value == "Safe for use") {
				statusText.value = "Time-critical issue";
				return;
			}
			if (statusText.value == "Time-critical issue") {
				statusText.value = "Do not use";
				return;
			}
			if (statusText.value == "Do not use") {
				statusText.value = "Unchecked";
				return;
			}
			if (statusText.value == "Unchecked") {
				statusText.value = "Missing";
				return;
			}
			if (statusText.value == "Missing") {
				statusText.value = "Status (All)";
				return;
			}
		};

		const searchedItems = computed(() => {
			if (items.value) {
				let localItems = [...items.value];

				if (userDoc.value) {
					localItems = localItems.filter((item) => {
						return userDoc.value.locations.some((f) => {
							return f === item.locationId;
						});
					});
				}

				if (searchTags.value.length) {
					localItems = localItems.filter((item) => {
						return searchTags.value
							.map((t) => t.toLowerCase())
							.every((t) =>
								item.tags
									.map((i) => i.toLowerCase())
									.includes(t)
							);
					});
				}

				if (statusText.value == "Status (All)") {
					localItems = localItems.filter((item) => {
						if (!item.checkedStatus) {
							item.checkedStatus = "Unchecked";
						}

						return (
							item.identifier
								.toLowerCase()
								.includes(searchTag.value.toLowerCase()) ||
							item.name
								.toLowerCase()
								.includes(searchTag.value.toLowerCase()) ||
							item.tags
								.map((tag) => tag.toLowerCase())
								.some((tag) =>
									tag.includes(searchTag.value.toLowerCase())
								)
						);
					});
				} else {
					localItems = localItems.filter((item) => {
						if (!item.checkedStatus) {
							item.checkedStatus = "Unchecked";
						}

						return (
							(item.identifier
								.toLowerCase()
								.includes(searchTag.value.toLowerCase()) ||
								item.name
									.toLowerCase()
									.includes(searchTag.value.toLowerCase()) ||
								item.tags
									.map((tag) => tag.toLowerCase())
									.some((tag) =>
										tag.includes(
											searchTag.value.toLowerCase()
										)
									)) &&
							item.checkedStatus == statusText.value
						);
					});
				}

				localItems.forEach((item) => {
					item.isChecked = selectAll.value ? true : false;
				});

				countNames(localItems);

				return localItems;
			}
		});

		const renderedItems = computed(() => {
			return searchedItems.value.slice(0, renderedItemsCount.value);
		});

		const showEdit = computed(() => {
			if (searchedItems.value) {
				return searchedItems.value.some((item) => item.isChecked);
			}
		});

		const bulkUpdate = () => {
			dataToUpdate.value = searchedItems.value.filter((item) => {
				return item.isChecked == true;
			});

			showForm(dataToUpdate.value);
		};

		const nameCount = ref();
		const equipmentNames = ref([]);

		const countNames = (filteredItems) => {
			let namesList = [];

			filteredItems.forEach((item) => {
				namesList.push(item.name);
			});

			nameCount.value = namesList.reduce(function (acc, curr) {
				return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
			}, {});

			equipmentNames.value = [...new Set(namesList)];
		};

		const empty = computed(() => {
			if (searchedItems.value) {
				return searchedItems.value.length == 0;
			}
		});

		const addSearchTag = (e) => {
			if (searchTag.value) {
				let tag = searchTag.value.trim();

				if (!searchTags.value.includes(tag)) {
					searchTags.value.push(tag);
				}
				searchTag.value = "";
			}
		};

		const addNameFilter = (name) => {
			if (!searchTags.value.includes(name)) {
				searchTags.value.push(name);
			}
		};

		const deleteSearchTag = (searchTag) => {
			searchTags.value = searchTags.value.filter((item) => {
				return searchTag !== item;
			});
		};

		const tagSearched = (value) => {
			if (searchTags.value.length > 0) {
				var index = searchTags.value.findIndex(
					(item) => item.toLowerCase() == value.toLowerCase()
				);
				if (index == -1) {
					return false;
				} else {
					return true;
				}
			} else {
				return false;
			}
		};

		const access = computed(() => {
			if (userDoc.value) {
				return (
					userDoc.value.role == "Admin" ||
					userDoc.value.role == "Contributor"
				);
			}
		});

		const isExpanded = ref(false);

		const expand = () => {
			if (isExpanded.value == false) {
				isExpanded.value = true;
			} else {
				isExpanded.value = false;
			}
		};

		watchEffect(() => {
			if (searchedItems.value) {
				if (renderedItemsCount.value >= searchedItems.value.length) {
					showMore.value = false;
				} else if (
					renderedItemsCount.value < searchedItems.value.length
				) {
					showMore.value = true;
				}
			}
		});

		return {
			error,
			searchQuery,
			searchedItems,
			items,
			addSearchTag,
			deleteSearchTag,
			tagSearched,
			searchTags,
			searchTag,
			empty,
			access,
			role,
			nameCount,
			equipmentNames,
			expand,
			isExpanded,
			switchStatus,
			statusText,
			addNameFilter,
			selectAll,
			bulkUpdate,
			showForm,
			showEdit,
			loading,
			renderedItemsCount,
			renderMore,
			renderedItems,
			showMore,
		};
	},
};
</script>

<style lang="scss" scoped>
#tag-search {
	width: 30ch;
	&::placeholder {
		font-size: 1em !important;
	}
}
.status-toggle {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5em;

	cursor: pointer;
	color: $rm-blue;
	font-weight: bold;
	&:hover {
		color: $rm-pink;
	}
}

.tags-container {
	display: flex;
	align-items: center;
	overflow: auto;
	cursor: default;

	@include respond(larger) {
		// padding-bottom: 0.5em;
	}
	.icon {
		padding: 0 1em;
		font-size: 1em;
		color: $rm-dark-blue;
		font-weight: bold;
		&:hover {
			color: $rm-pink;
			cursor: pointer;
		}
	}
}
.tags-modal {
	display: flex;
	width: 40vw;
	flex-wrap: wrap;
	column-gap: 1.2em;
	row-gap: 1.2em;
	.item {
		display: flex;
		gap: 0.5em;
		padding: 0.3em 1em;
		background-color: white;
		border: 1px solid $rm-light-grey;
		border-radius: $border-radius;
		// box-shadow: $box-shadow;
		flex-shrink: 0;
		font-size: 1em;

		.name {
			&::after {
				content: ":";
			}
		}
		.number {
			font-weight: 600;
		}
	}
}

#nav-pill {
	background-color: $rm-grey;
	color: $rm-light-grey;
}
.name-count {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	column-gap: 0.5em;
	overflow: auto;
	padding-right: 50px;
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;
	.item {
		display: flex;
		width: max-content;
		gap: 0.5em;
		padding: 0.2em 0.8em;
		background-color: white;
		border: 1px solid $rm-light-grey;
		border-radius: $border-radius;
		// box-shadow: $box-shadow;
		flex-shrink: 0;

		@include respond(small) {
			padding: 0.3em 1em;
		}
		@include respond(large) {
			padding: 0.2em 0.8em;
		}
		.name {
			&::after {
				content: ":";
			}
		}
		.number {
			font-weight: 600;
		}
		&:hover {
			cursor: pointer;
			// border: 2px solid $rm-pink;
			background-color: $rm-blue;
			color: white;
		}
	}
}
.table-row {
	.row-left {
		width: inherit;
		max-width: 90%;

		.tags {
			margin-left: 2em;
			display: flex;
			gap: 1em;
			padding-right: 100px;
		}
	}

	.pill-hover {
		cursor: pointer;
		&:hover {
			background-color: $rm-blue;
			color: white;
		}
	}
}
.list-container {
	top: 250px;
	@include respond(small) {
		top: 250px !important;
	}
	@include respond(medium) {
		top: 170px !important;
	}
}
.nav-row {
	@include respond(large) {
		padding: 0 1.5em !important;
		height: 50px !important;
	}
}

.sub-nav-right {
	@include respond(medium) {
		gap: 10px !important;
	}
	@include respond(large) {
		gap: 1em !important;
	}
}
.no-list {
	padding-bottom: 50vh;

	@include respond(large) {
		padding-left: 1em;
	}
}
.edit-container {
	cursor: pointer;
}

// custom check box
.select-all-container {
	margin: 0 0.9em 0 0.7em;
}
.check-container {
	margin: 0 0 0 1em;
}
.custom-checkbox {
	display: inline-block;
	position: relative;
	padding-left: 12px;
	margin-bottom: 15px;
	cursor: pointer;
	font-size: 1em;
	user-select: none;
}

.custom-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

.checkmark {
	position: absolute;
	top: 1px;
	left: 0;
	height: 1.25em;
	width: 1.25em;
	border: 1px solid $rm-grey;
	border-radius: 50%;
}

.custom-checkbox input:checked ~ .checkmark:after {
	content: "";
	position: absolute;
	display: block;
	left: 5.5px;
	top: 2px;
	width: 4px;
	height: 8px;
	border: solid $rm-dark-blue;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
	border-color: white;
}

.custom-checkbox:hover input ~ .checkmark {
	background-color: $rm-light-grey;
}

.custom-checkbox input:checked ~ .checkmark {
	background-color: $rm-blue;
	border-color: $rm-dark-blue;
}
.search-input {
	flex-direction: column;
	@include respond(small) {
		flex-direction: row;
	}
}
.sub-nav-left {
	padding: 0;
	@include respond(small) {
		padding: 1em;
	}
}
</style>
