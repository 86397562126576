<template>
	<transition name="fade" mode="out-in">
		<Popup v-if="isExpanded">
			<template #modal-header-content>
				<div class="modal-title">
					<h3>Equipment count</h3>
				</div>
				<div class="btn-icon muted" @click="expand">
					close
					<fa icon="times" />
				</div>
			</template>
			<template #modal-body-content>
				<div
					class="tags-modal"
					v-if="nameCount && equipmentNames.length && !searchTag"
				>
					<div
						class="item"
						v-for="name in equipmentNames"
						:key="name"
					>
						<div class="name">{{ name }}</div>
						<div class="number">{{ nameCount[name] }}</div>
					</div>
				</div>
			</template>
		</Popup>
	</transition>
	<div class="right-content">
		<EquipmentDashNav :access="access" />
		<div class="page-content">
			<div class="page-detail">
				<sub-nav-loading v-if="!nameCount" />
				<!-- Sub Nav -->
				<div v-else class="right-sub-nav fixed">
					<div class="nav-row search-input">
						<div class="sub-nav-left">
							<div class="search">
								<fa class="search-icon" icon="search" />
								<input
									id="tag-search"
									type="search"
									placeholder="Search or filter by tags..."
									v-model="searchTag"
									@keyup.enter="addSearchTag"
								/>
							</div>
							<!-- Filter tags -->
							<div
								class="pill-container fade-row"
								v-if="searchTags.length"
							>
								<div
									v-for="searchTag in searchTags"
									:key="searchTag"
									class="pill bg-blue"
								>
									<fa class="fa-tag" icon="tag" />
									{{ searchTag }}
									<fa
										class="fa-times"
										icon="times"
										@click="deleteSearchTag(searchTag)"
									/>
								</div>
							</div>
						</div>
						<div class="sub-nav-right">
							<!-- Record count -->
							<div v-if="items && searchedItems">
								<b>
									{{ searchedItems.length }}
									<span v-if="searchedItems.length == 1"
										>record</span
									>
									<span v-if="searchedItems.length > 1"
										>archived records</span
									>
								</b>
							</div>

							<!-- Toggle between statuses -->
							<!-- <div
								class="status-toggle"
								@click="switchStatus"
								v-if="items && searchedItems"
							>
								<fa icon="angle-left" />
								<p>{{ statusText }}</p>
								<fa icon="angle-right" />
							</div> -->

							<!-- Select equipment -->
							<div
								v-if="access && showEdit"
								class="edit-container"
							>
								<div
									class="btn-link"
									v-on:click.prevent="bulkRestore"
								>
									<p>
										Restore Items &nbsp;
										<span>
											<fa icon="trash-restore" />
										</span>
									</p>
								</div>
							</div>
						</div>
					</div>

					<!-- List of clickable item names that adds item name to tags search -->
					<div class="nav-row">
						<div
							class="tags-container"
							v-if="items && searchedItems"
						>
							<div
								class="name-count fade-row"
								v-if="
									nameCount &&
									equipmentNames.length &&
									!searchTag
								"
							>
								<div v-for="name in equipmentNames" :key="name">
									<div
										class="item"
										@click="addNameFilter(name)"
									>
										<div class="name">{{ name }}</div>
										<div class="number">
											{{ nameCount[name] }}
										</div>
									</div>
								</div>
							</div>
							<fa
								icon="expand-alt"
								class="icon"
								@click="expand"
								v-if="
									nameCount &&
									equipmentNames.length &&
									!searchTag &&
									equipmentNames.length > 6
								"
							/>
						</div>
						<div class="select-all-container" v-if="access">
							<label for="my-checkbox" class="custom-checkbox">
								<input
									type="checkbox"
									id="my-checkbox"
									v-model="selectAll"
								/>
								<span class="checkmark"></span>
							</label>
						</div>
					</div>
				</div>

				<!-- Loading -->
				<list-loading v-if="!items" />

				<!-- Items List -->
				<div v-else class="list-container">
					<!-- If items are empty -->
					<div class="no-list vertical-center" v-if="empty">
						<div class="icon">
							<fa icon="tools" />
						</div>
						<p>No Equipment found</p>
						<div v-if="role != 'Admin'">
							<br />
							<p>
								Ensure that you have been granted access to the
								location where the equipment records are logged
							</p>
						</div>
					</div>

					<div class="table-row-container euip-table-margin">
						<div
							v-for="(item, index) in searchedItems"
							:key="item.id"
						>
							<div>
								<div :class="{ tour_item: index === 0 }">
									<div class="table-row row-data">
										<div class="row-left">
											<div
												class="circle-avatar"
												:style="`background-color:${item.color}`"
											>
												{{ item.initials }}
											</div>

											<div class="title">
												<router-link
													:to="{
														name: 'EquipmentDetail',
														params: {
															id: item.id,
														},
													}"
												>
													{{ item.name }}
												</router-link>
											</div>
											<div class="description">
												<router-link
													:to="{
														name: 'EquipmentDetail',
														params: {
															id: item.id,
														},
													}"
												>
													{{ item.id }}
												</router-link>
											</div>

											<div class="tags fade-row">
												<div
													v-for="tag in item.tags"
													:key="tag"
												>
													<div
														@click="
															addNameFilter(tag)
														"
														class="pill-hover"
														:class="
															tagSearched(tag) ===
															true
																? 'pill bg-blue'
																: 'pill'
														"
													>
														<div class="content">
															<fa
																class="fa-tag"
																icon="tag"
															/>
															<p>{{ tag }}</p>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div class="row-right">
											<div class="actions">
												<div class="flex">
													<router-link
														:to="{
															name: 'EquipmentDetail',
															params: {
																id: item.id,
															},
														}"
														class="row-link"
													>
														<fa icon="eye" />
													</router-link>
													<!-- <div class="status">
														<fa
															class="success"
															icon="check-circle"
															v-if="
																item.checkedStatus ==
																'Safe for use'
															"
														/>
														<fa
															class="warning"
															icon="flag"
															v-if="
																item.checkedStatus ==
																'Time-critical issue'
															"
														/>
														<fa
															class="danger"
															icon="exclamation-triangle"
															v-if="
																item.checkedStatus ==
																'Do not use'
															"
														/>
														<fa
															class="muted"
															icon="search"
															v-if="
																item.checkedStatus ==
																'Missing'
															"
														/>
														<fa
															class="muted"
															icon="question-circle"
															v-if="
																item.checkedStatus ==
																'Unchecked'
															"
														/>
														<fa
															class="muted"
															icon="question-circle"
															v-if="
																!item.checkedStatus
															"
														/>
													</div> -->

													<div
														class="check-container"
														v-if="access"
													>
														<label
															:for="
																'my-checkbox-' +
																item.id
															"
															class="custom-checkbox"
														>
															<input
																type="checkbox"
																:id="
																	'my-checkbox-' +
																	item.id
																"
																v-model="
																	item.isChecked
																"
															/>
															<span
																class="checkmark"
															></span>
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<transition name="fade" mode="out-in">
		<router-view></router-view>
	</transition>
</template>

<script>
import EquipmentDashNav from "../../components/equipment/EquipmentDashNav";
import getCollection from "../../composables/getCollection";
import { projectFirestore, timestampFromDate } from "../../firebase/config";
import { computed, ref } from "@vue/runtime-core";
import account from "../../composables/account";
import { useMeta } from "vue-meta";
import ListLoading from "../../components/ListLoading.vue";
import SubNavLoading from "../../components/SubNavLoading.vue";
import Popup from "../../components/Popup.vue";
import { useRouter } from "vue-router";

export default {
	name: "EquipmentDash",
	components: { EquipmentDashNav, ListLoading, SubNavLoading, Popup },
	setup() {
		useMeta({ title: "Equipment" });
		const { companyId, userDoc, error, role } = account();

		const searchQuery = ref("");
		const searchTag = ref("");
		const searchTags = ref([]);
		const selectAll = ref(false);
		const db = projectFirestore;
		const dataToUpdate = ref(null);
		const router = useRouter();

		const equipmentRef = db
			.collection("companies")
			.doc(companyId)
			.collection("equipment");

		const getEquipment = equipmentRef
			.where("archived", "==", true)
			.orderBy("createdAt", "desc");
		const { documents: items } = getCollection(getEquipment);

		const filteredItems = ref(null);
		const statusText = ref("Status (All)");

		const showForm = (data) => {
			if (data.length > 0) {
				router.push({
					name: "UpdateEquipment",
					query: {
						id: "bulk-update",
						bulkData: JSON.stringify(data),
					},
				});
			}
		};

		const switchStatus = () => {
			if (statusText.value == "Status (All)") {
				statusText.value = "Safe for use";
				return;
			}
			if (statusText.value == "Safe for use") {
				statusText.value = "Time-critical issue";
				return;
			}
			if (statusText.value == "Time-critical issue") {
				statusText.value = "Do not use";
				return;
			}
			if (statusText.value == "Do not use") {
				statusText.value = "Unchecked";
				return;
			}
			if (statusText.value == "Unchecked") {
				statusText.value = "Missing";
				return;
			}
			if (statusText.value == "Missing") {
				statusText.value = "Status (All)";
				return;
			}
		};

		const searchedItems = computed(() => {
			if (items.value) {
				let localItems = [...items.value];

				if (userDoc.value) {
					localItems = localItems.filter((item) => {
						return userDoc.value.locations.some((f) => {
							return f === item.locationId;
						});
					});
				}

				if (searchTags.value.length) {
					localItems = localItems.filter((item) => {
						return searchTags.value
							.map((t) => t.toLowerCase())
							.every((t) =>
								item.tags
									.map((i) => i.toLowerCase())
									.includes(t)
							);
					});
				}

				countNames(localItems);

				if (statusText.value == "Status (All)") {
					localItems = localItems.filter((item) => {
						if (!item.checkedStatus) {
							item.checkedStatus = "Unchecked";
						}

						return (
							item.identifier
								.toLowerCase()
								.includes(searchTag.value.toLowerCase()) ||
							item.name
								.toLowerCase()
								.includes(searchTag.value.toLowerCase()) ||
							item.tags
								.map((tag) => tag.toLowerCase())
								.some((tag) =>
									tag.includes(searchTag.value.toLowerCase())
								)
						);
					});
				} else {
					localItems = localItems.filter((item) => {
						if (!item.checkedStatus) {
							item.checkedStatus = "Unchecked";
						}

						return (
							(item.identifier
								.toLowerCase()
								.includes(searchTag.value.toLowerCase()) ||
								item.name
									.toLowerCase()
									.includes(searchTag.value.toLowerCase()) ||
								item.tags
									.map((tag) => tag.toLowerCase())
									.some((tag) =>
										tag.includes(
											searchTag.value.toLowerCase()
										)
									)) &&
							item.checkedStatus == statusText.value
						);
					});
				}

				countNames(localItems);

				localItems.forEach((item) => {
					item.isChecked = selectAll.value ? true : false;
				});

				return localItems;
			}
		});

		const showEdit = computed(() => {
			if (searchedItems.value) {
				return searchedItems.value.some((item) => item.isChecked);
			}
		});

		const bulkRestore = () => {
			let now = new Date();
			let timestamp = timestampFromDate(now);
			dataToUpdate.value = searchedItems.value.filter((item) => {
				return item.isChecked == true;
			});

			let batch = projectFirestore.batch();
			let count = 0;

			dataToUpdate.value.forEach((item, index) => {
				if (count >= 500) {
					batch.commit();
					batch = firestore.batch();
					count = 0;
				}

				const docRef = equipmentRef.doc(item.id);
				batch.update(docRef, {
					archived: false,
					lastUpdated: timestamp,
				});
				count++;
			});

			batch.commit();
		};

		const nameCount = ref();
		const equipmentNames = ref([]);

		const countNames = (filteredItems) => {
			let namesList = [];

			filteredItems.forEach((item) => {
				namesList.push(item.name);
			});

			nameCount.value = namesList.reduce(function (acc, curr) {
				return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
			}, {});

			equipmentNames.value = [...new Set(namesList)];
		};

		const empty = computed(() => {
			if (searchedItems.value) {
				return searchedItems.value.length == 0;
			}
		});

		const addSearchTag = (e) => {
			if (searchTag.value) {
				let tag = searchTag.value.trim();

				if (!searchTags.value.includes(tag)) {
					searchTags.value.push(tag);
				}
				searchTag.value = "";
			}
		};

		const addNameFilter = (name) => {
			if (!searchTags.value.includes(name)) {
				searchTags.value.push(name);
			}
		};

		const deleteSearchTag = (searchTag) => {
			searchTags.value = searchTags.value.filter((item) => {
				return searchTag !== item;
			});
		};

		const tagSearched = (value) => {
			if (searchTags.value.length > 0) {
				var index = searchTags.value.findIndex(
					(item) => item.toLowerCase() == value.toLowerCase()
				);
				if (index == -1) {
					return false;
				} else {
					return true;
				}
			} else {
				return false;
			}
		};

		const access = computed(() => {
			if (userDoc.value) {
				return (
					userDoc.value.role == "Admin" ||
					userDoc.value.role == "Contributor"
				);
			}
		});

		const isExpanded = ref(false);

		const expand = () => {
			if (isExpanded.value == false) {
				isExpanded.value = true;
			} else {
				isExpanded.value = false;
			}
		};

		return {
			error,
			searchQuery,
			searchedItems,
			items,
			addSearchTag,
			deleteSearchTag,
			tagSearched,
			searchTags,
			searchTag,
			empty,
			access,
			role,
			nameCount,
			equipmentNames,
			expand,
			isExpanded,
			switchStatus,
			statusText,
			addNameFilter,
			selectAll,
			showForm,
			showEdit,
			bulkRestore,
		};
	},
};
</script>

<style lang="scss" scoped>
#tag-search {
	width: 30ch;
	&::placeholder {
		font-size: 1em !important;
	}
}
.status-toggle {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5em;

	cursor: pointer;
	color: $rm-blue;
	font-weight: bold;
	&:hover {
		color: $rm-pink;
	}
}

.tags-container {
	display: flex;
	align-items: center;
	overflow: auto;
	cursor: default;

	@include respond(larger) {
		// padding-bottom: 0.5em;
	}
	.icon {
		padding: 0 1em;
		font-size: 1em;
		color: $rm-dark-blue;
		font-weight: bold;
		&:hover {
			color: $rm-pink;
			cursor: pointer;
		}
	}
	// &::-webkit-scrollbar {
	//     display: none;
	// }
	// -ms-overflow-style: none; /* IE and Edge */
	// scrollbar-width: none;
}
.tags-modal {
	display: flex;
	width: 40vw;
	flex-wrap: wrap;
	column-gap: 1.2em;
	row-gap: 1.2em;
	.item {
		display: flex;
		gap: 0.5em;
		padding: 0.3em 1em;
		background-color: white;
		border: 1px solid $rm-light-grey;
		border-radius: $border-radius;
		// box-shadow: $box-shadow;
		flex-shrink: 0;
		font-size: 1em;

		.name {
			&::after {
				content: ":";
			}
		}
		.number {
			font-weight: 600;
		}
	}
}

#nav-pill {
	background-color: $rm-grey;
	color: $rm-light-grey;
}
.name-count {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	column-gap: 0.5em;
	overflow: auto;
	padding-right: 50px;
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;
	.item {
		display: flex;
		width: max-content;
		gap: 0.5em;
		padding: 0.2em 0.8em;
		background-color: white;
		border: 1px solid $rm-light-grey;
		border-radius: $border-radius;
		// box-shadow: $box-shadow;
		flex-shrink: 0;

		@include respond(small) {
			padding: 0.3em 1em;
		}
		@include respond(large) {
			padding: 0.2em 0.8em;
		}
		.name {
			&::after {
				content: ":";
			}
		}
		.number {
			font-weight: 600;
		}
		&:hover {
			cursor: pointer;
			// border: 2px solid $rm-pink;
			background-color: $rm-blue;
			color: white;
		}
	}
}
.table-row {
	.row-left {
		.title {
			width: 18%;
			max-width: 200px;
		}
		.description {
			width: 18%;
			max-width: 200px;
		}
		width: inherit;
		max-width: 90%;
		.tags {
			display: flex;
			gap: 1em;
			// overflow: auto;
			padding-right: 100px;
		}
	}

	.pill-hover {
		cursor: pointer;
		&:hover {
			background-color: $rm-blue;
			color: white;
		}
	}
	.status {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 35px !important;
		height: 35px !important;
		font-size: 1.2em;
	}
	.row-link {
		padding-right: 1em;
		color: $rm-grey;
		font-size: 1em;
		&:hover {
			color: $rm-pink;
		}
	}
}
.list-container {
	top: 250px;
	@include respond(small) {
		top: 250px !important;
	}
	@include respond(medium) {
		top: 170px !important;
	}
}
.nav-row-both {
	@include respond(medium) {
		padding: 0 1em !important;
	}
	@include respond(large) {
		font-size: 1em !important;
		padding: 0 1.5em !important;
	}
}
.nav-row {
	@include respond(large) {
		padding: 0 1.5em !important;
		height: 50px !important;
	}
}

.right-sub-nav {
	// gap: 1em !important;
	//height: 100px !important;
	@include respond(large) {
		// gap: 0.7em !important;
	}
}
.sub-nav-right {
	@include respond(medium) {
		gap: 10px !important;
	}
	@include respond(large) {
		gap: 1em !important;
	}
}
.no-list {
	padding-bottom: 50vh;

	@include respond(large) {
		padding-left: 1em;
	}
}
.select-box {
	margin: 0 0 0 1em;
}
.select-box-all {
	margin: 0 1em;
}
.add-icon {
	margin: 0 0.6em 0 1em;
	font-size: 1.2em;
}
.edit-container {
	cursor: pointer;
}

// custom check box
.select-all-container {
	margin: 0 0.9em 0 0.7em;
}
.check-container {
	margin: 0 0 0 1em;
}
.custom-checkbox {
	display: inline-block;
	position: relative;
	padding-left: 12px;
	margin-bottom: 15px;
	cursor: pointer;
	font-size: 1em;
	user-select: none;
}

.custom-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

.checkmark {
	position: absolute;
	top: 1px;
	left: 0;
	height: 1.25em;
	width: 1.25em;
	border: 1px solid $rm-grey;
	border-radius: 50%;
}

.custom-checkbox input:checked ~ .checkmark:after {
	content: "";
	position: absolute;
	display: block;
	left: 5.5px;
	top: 2px;
	width: 4px;
	height: 8px;
	border: solid $rm-dark-blue;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
	border-color: white;
}

.custom-checkbox:hover input ~ .checkmark {
	background-color: $rm-light-grey;
}

.custom-checkbox input:checked ~ .checkmark {
	background-color: $rm-blue;
	border-color: $rm-dark-blue;
}
.search-input {
	flex-direction: column;
	@include respond(small) {
		flex-direction: row;
	}
}
.sub-nav-left {
	padding: 0;
	@include respond(small) {
		padding: 1em;
	}
}
</style>
