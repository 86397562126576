<template>
	<div class="modal">
		<div class="modal-container">
			<div class="modal-content tile">
				<div class="modal-header">
					<div class="modal-title">
						<h3>
							{{ mode === "Add" ? "Add New Note" : "Edit Note" }}
						</h3>
					</div>
					<div class="close" @click="closeModal">
						<fa icon="times" />
					</div>
				</div>
				<form
					id="modal-form"
					class="rm-form"
					@submit.prevent="handleSubmit"
				>
					<div class="spacer-20"></div>
					<div class="form-input">
						<input
							type="text"
							required
							v-model="newNote.noteDesc"
							placeholder="Note description..."
						/>
					</div>

					<div class="form-submit">
						<button
							type="submit"
							class="btn btn-primary"
							@click="addNote"
							:disabled="isNoteEmpty"
						>
							<div class="text">Save</div>
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, computed, onMounted } from "@vue/runtime-core";
import equipmentCollection from "../../composables/equipmentsCollection";
import { useRouter, useRoute } from "vue-router";
import { v4 as uuidv4 } from "uuid";
import account from "../../composables/account";
export default {
	name: "AddEditEquipmentNote",
	setup() {
		const route = useRoute();
		var selectedNote = {};
		if (route.query.selectedNote != undefined) {
			if (route.query.selectedNote.length > 0) {
				selectedNote = JSON.parse(route.query.selectedNote);
			}
		}

		const id = uuidv4();
		const { user } = account();
		const router = useRouter();

		const { addEquipmentNote, updateEquipmentNote } = equipmentCollection();

		const newNote = ref({ noteDesc: "" });

		const isNoteEmpty = computed(() => {
			return !newNote.value.noteDesc || !newNote.value.noteDesc.trim();
		});

		const addNote = () => {
			const note = {
				noteDesc: newNote.value.noteDesc,
			};

			try {
				if (route.query.noteId == undefined) {
					addEquipmentNote(
						route.query.equipmentId,
						note,
						id,
						user.value.uid
					);
					newNote.value = { noteDesc: "" };
					router.push({
						name: "EquipmentDetail",
						params: { id: route.query.equipmentId },
					});
				} else {
					updateEquipmentNote(
						route.query.equipmentId,
						note,
						route.query.noteId,
						user.value.uid
					);
				}
				newNote.value = { noteDesc: "" };
				if (route.query.equipmentANoteRoute) {
					router.push({
						name: route.query.equipmentANoteRoute,
						params: { id: route.query.equipmentId },
					});
				} else if (route.query.notesRoute) {
					router.push({
						name: route.query.notesRoute,
						params: { id: route.query.equipmentId },
					});
				}
			} catch (error) {
				console.log("Error while adding note", error);
			}
		};
		const closeModal = () => {
			if (route.query.mode === "Edit") {
				if (route.query.equipmentANoteRoute) {
					router.push({
						name: route.query.equipmentANoteRoute,
						params: { id: route.query.equipmentId },
					});
				} else if (route.query.notesRoute) {
					router.push({
						name: route.query.notesRoute,
						params: { id: route.query.equipmentId },
					});
				}
			} else {
				router.push({
					name: "EquipmentDetail",
					params: { id: route.query.equipmentId },
				});
			}
		};

		onMounted(() => {
			if (selectedNote) {
				newNote.value = {
					noteDesc: selectedNote.content,
				};
			}
		});
		return {
			newNote,
			addNote,
			selectedNote,
			isNoteEmpty,
			closeModal,
		};
	},
};
</script>

<style></style>
