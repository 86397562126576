<template>
  <div class="modal">
    <div class="modal-container">
      <div class="modal-content tile">
        <div class="modal-header">
          <div class="modal-title">
            <h3>Equipments</h3>
          </div>
          <button @click="handleSubmit">Select</button>
          <router-link class="close" :to="{ name: 'TemplateBuilder'  ,params: { data: value } }">
            <fa icon="times" />
          </router-link>
        </div>
        <div class="modal-body">
          <Multiselect
            v-model="value"
            mode="tags"
            label="name"
            trackBy="name"
            placeholder="Select your characters"
            :options="options"
            :searchable="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "@vue/runtime-core";
import equipmentCollection from "../../composables/equipmentsCollection";
import { ref } from "@vue/reactivity";
import Multiselect from "@vueform/multiselect";
import { useRouter } from "vue-router";
import templateService from "../../composables/templateService"

export default {
  name: "LinkEquipment",
  components: {
    Multiselect,
  },

  setup(props, context) {
    const { getEquipment, equipments, error } = equipmentCollection();
    const router = useRouter()
    const value = ref([]);

    getEquipment();

    const options = computed(() => {
      console.log(equipments.value.value, equipments.value.value);
      if (equipments.value.value != null) {
        equipments.value.value.map((equipment) => {
          equipment.value = equipment.id;
        });
      }

      return equipments.value.value;
    });


    const handleSubmit = ()=>{
      console.log(value,"selected value")
      templateService.setEquipments(value)
    
    }

    return { value, options, equipments,handleSubmit };
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"   scoped>

.modal .modal-container .modal-content .modal-header {
     box-shadow: none;
     margin-left: -40px;
     margin-right: -40px;
     padding: 0px 40px 1rem;
    
}

.modal-title {
     display: flex;
     justify-content: flex-start;
     align-items: center;
     width: 35ch;
     overflow: hidden;
     padding: 0 1rem;
    

}
</style>



