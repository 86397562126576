<template>
  <div class="modal">
    <div class="modal-container">
      <div class="modal-content tile">
        <div class="modal-header">
          <div class="modal-title">
            <h3>Users</h3>
          </div>
          <button @click="handleSubmit">Select</button>
          <router-link class="close" :to="{ name: 'TemplateBuilder'  ,params: { data: value } }">
            <fa icon="times" />
          </router-link>
        </div>
        <div class="modal-body">
          <Multiselect
            v-model="value"
            mode="tags"
            label="name"
            trackBy="name"
            placeholder="Select your characters"
            :options="options"
            :searchable="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "@vue/runtime-core";
import locationCollection from "../../composables/locationCollection";
import { ref } from "@vue/reactivity";
import Multiselect from "@vueform/multiselect";
import { useRouter } from "vue-router";
import templateService from "../../composables/templateService"

export default {
  name: "LinkUsers",
  components: {
    Multiselect,
  },

  setup(props, context) {
    const { getLocation, locations, error } = locationCollection();
    const router = useRouter()
    const value = ref([]);

    getLocation();

    const options = computed(() => {

      if (locations.value.value != null) {
        locations.value.value.map((location) => {
           
            var name = location.locationName
          location.value = location.id;
          location.name = name
        });
      }
   
      return locations.value.value;
    });


    const handleSubmit = ()=>{
      console.log(value,"selected value")
      templateService.setLocations(value)
    
    }

    return { value, options, locations,handleSubmit };
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"  scoped>

.modal .modal-container .modal-content .modal-header {
     box-shadow: none;
     margin-left: -40px;
     margin-right: -40px;
     padding: 0px 40px 1rem;
    
}

.modal-title {
     display: flex;
     justify-content: flex-start;
     align-items: center;
     width: 35ch;
     overflow: hidden;
     padding: 0 1rem;
    

}
</style>



