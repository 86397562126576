<template>
	<div>
		<alert :message="error" v-if="error" />
		<alert
			message="Can not add more equipment. Please upgrade."
			v-if="!canAddEquipments"
		/>
		<div class="modal">
			<div class="modal-container">
				<div class="modal-content tile">
					<form class="rm-form" @submit.prevent="handelSubmit">
						<div class="modal-header">
							<div class="left">
								<div
									class="modal-title"
									v-if="bulkData.length == 0"
								>
									<div class="circle-avatar" :id="color">
										{{ addInitials }}
									</div>
									<h3 v-if="!equipmentName">
										New Equipment Item
									</h3>
									<h3 v-else>{{ equipmentName }}</h3>
								</div>
								<div class="modal-title" v-else>
									<div class="circle-avatar" id="grey">
										<fa icon="tools" />
									</div>
									<h3>Edit Selected Items</h3>
								</div>
							</div>
							<div class="right">
								<div class="form-submit" v-if="!error">
									<page-loading v-if="isLoading" />
									<div v-else class="save-option">
										<div
											class="btn btn-icon muted"
											@click="archive"
											v-if="
												bulkData.length == 0 && isUpdate
											"
										>
											<div class="text">archive</div>
											<fa icon="archive" />
										</div>
										<div
											class="btn btn-icon muted"
											@click="handleArchiveAll"
											v-if="bulkData.length > 0"
										>
											<div class="text danger">
												Archive All
											</div>
											<fa icon="archive" class="danger" />
										</div>
										<select
											class="save-options"
											v-model="saveOptionSelected"
											v-if="bulkData.length == 0"
										>
											<option vlaue="Save & close">
												Save &amp; close
											</option>
											<option vlaue="Save & duplicate">
												Save &amp; duplicate
											</option>
											<option vlaue="Save & clear">
												Save &amp; clear
											</option>
										</select>
										<button
											class="btn btn-primary"
											:disabled="
												validateIdentifier ||
												validateDotIdentifier ||
												validateSlashIdentifier ||
												validateMultipleDotIdentifier ||
												identifierExists ||
												formDisabled
											"
										>
											<div class="text">Save</div>
										</button>
										<!-- <button class="btn btn-danger btn-small">
                                            <fa icon="archive" />
                                        </button>-->
									</div>
								</div>
								<div class="error-text" v-if="error">
									{{ error }}
								</div>

								<router-link
									v-if="isUpdate && identifier && !isLoading"
									class="close"
									:to="{
										name: 'EquipmentDetail',
										params: { id: identifier },
									}"
								>
									<fa icon="times" />
								</router-link>
								<router-link
									v-else-if="!isLoading"
									class="close"
									:to="{ name: 'EquipmentDash' }"
								>
									<fa icon="times" />
								</router-link>
							</div>
						</div>

						<div class="split">
							<div class="p-1rem">
								<div class="form-input">
									<label v-if="bulkData.length == 0"
										>Item Name:</label
									>
									<label v-else>Change Item Names To:</label>

									<div
										class="validation-text"
										v-if="validateName"
									>
										Must be no more than three words
									</div>
									<vue-select
										@submit.prevent="handelSubmit"
										v-model="equipmentName"
										:options="equipmentNames"
										searchable
										clear-on-select
										clear-on-close
										close-on-select
										search-placeholder="Type & select to add a new name"
										:placeholder="namePlaceholder"
										@blur="
											addTag(equipmentName),
												removeOldTag(equipmentName)
										"
										@search:input="handleNameInput"
									></vue-select>
								</div>

								<div
									class="form-input"
									v-if="bulkData.length == 0"
								>
									<label>Unique identifier:</label>
									<div
										class="validation-text"
										v-if="
											identifierExists &&
											!isLoading &&
											!isUpdate
										"
									>
										Identifier already exists!
									</div>
									<div
										class="validation-text"
										v-if="validateIdentifier"
									>
										Must be one word
									</div>
									<div
										class="validation-text"
										v-if="validateDotIdentifier"
									>
										Dot not allowed
									</div>
									<div
										class="validation-text"
										v-if="validateSlashIdentifier"
									>
										Slash not allowed
									</div>
									<div
										class="validation-text"
										v-if="validateMultipleDotIdentifier"
									>
										Multiple dots not allowed
									</div>
									<input
										class="constrain-input"
										:disabled="isUpdate"
										type="text"
										required
										v-model="identifier"
										placeholder="e.g. ID-001..."
									/>
								</div>

								<div
									class="form-input notes"
									v-if="bulkData.length == 0"
								>
									<label>Notes:</label>
									<input
										type="text"
										v-model="notes"
										placeholder="e.g. WLL 1.5KN..."
										maxlength="45"
									/>
								</div>

								<div class="form-input">
									<label v-if="bulkData.length == 0"
										>Item Location:</label
									>
									<label v-else
										>Change All Locations To:</label
									>
									<vue-select
										v-if="locations"
										@submit.prevent="handelSubmit"
										v-model="location"
										:options="locations"
										label-by="locationName"
										close-on-select
										:min="1"
										@blur="
											addTag(location.locationName),
												removeOldLocation(
													location.locationName
												)
										"
										:placeholder="locationPlaceholder"
									></vue-select>
									<input
										type="text"
										disabled
										value="Loading locations..."
										v-if="!locations"
									/>
								</div>

								<div class="radio-input">
									<div class="radio-label">
										<label v-if="bulkData.length == 0"
											>Item Color:</label
										>
										<label v-else
											>Change All Colors To:</label
										>
									</div>
									<div class="row">
										<div class="color-grid--equipment">
											<label class="radio-container">
												<input
													type="radio"
													value="purple"
													v-model="color"
												/>
												<span
													class="checkmark"
													id="purple"
												></span>
											</label>
											<label class="radio-container">
												<input
													type="radio"
													value="darkorange"
													v-model="color"
												/>
												<span
													class="checkmark"
													id="darkorange"
												></span>
											</label>
											<label class="radio-container">
												<input
													type="radio"
													value="aqua"
													v-model="color"
												/>
												<span
													class="checkmark"
													id="aqua"
												></span>
											</label>
											<label class="radio-container">
												<input
													type="radio"
													value="saddlebrown"
													v-model="color"
												/>
												<span
													class="checkmark"
													id="saddlebrown"
												></span>
											</label>
											<label class="radio-container">
												<input
													type="radio"
													value="deeppink"
													v-model="color"
												/>
												<span
													class="checkmark"
													id="deeppink"
												></span>
											</label>
											<label class="radio-container">
												<input
													type="radio"
													value="crimson"
													v-model="color"
												/>
												<span
													class="checkmark"
													id="crimson"
												></span>
											</label>
											<label class="radio-container">
												<input
													type="radio"
													value="darkolivegreen"
													v-model="color"
												/>
												<span
													class="checkmark"
													id="darkolivegreen"
												></span>
											</label>
											<label class="radio-container">
												<input
													type="radio"
													value="cornflowerblue"
													v-model="color"
												/>
												<span
													class="checkmark"
													id="cornflowerblue"
												></span>
											</label>
											<label class="radio-container">
												<input
													type="radio"
													value="gold"
													v-model="color"
												/>
												<span
													class="checkmark"
													id="gold"
												></span>
											</label>
											<label class="radio-container">
												<input
													type="radio"
													value="grey"
													v-model="color"
												/>
												<span
													class="checkmark"
													id="grey"
												></span>
											</label>
											<label class="radio-container">
												<input
													type="radio"
													value="salmon"
													v-model="color"
												/>
												<span
													class="checkmark"
													id="salmon"
												></span>
											</label>
											<label class="radio-container">
												<input
													type="radio"
													value="lightseagreen"
													v-model="color"
												/>
												<span
													class="checkmark"
													id="lightseagreen"
												></span>
											</label>
											<label class="radio-container">
												<input
													type="radio"
													value="mediumvioletred"
													v-model="color"
												/>
												<span
													class="checkmark"
													id="mediumvioletred"
												></span>
											</label>
											<label class="radio-container">
												<input
													type="radio"
													value="slateblue"
													v-model="color"
												/>
												<span
													class="checkmark"
													id="slateblue"
												></span>
											</label>
											<label class="radio-container">
												<input
													type="radio"
													value="orangered"
													v-model="color"
												/>
												<span
													class="checkmark"
													id="orangered"
												></span>
											</label>
											<label class="radio-container">
												<input
													type="radio"
													value="midnightblue"
													v-model="color"
												/>
												<span
													class="checkmark"
													id="midnightblue"
												></span>
											</label>
										</div>
									</div>
								</div>
							</div>
							<div class="p-1rem">
								<div class="form-input">
									<label v-if="bulkData.length == 0"
										>Status:</label
									>
									<label v-else
										>Change Every Status To:</label
									>
									<vue-select
										v-model="checkedStatus"
										:options="checkedStatusList"
										close-on-select
									></vue-select>
								</div>

								<div
									class="form-input"
									v-if="
										currentDoNotUseDate &&
										checkedStatus == 'Time-critical issue'
									"
								>
									<label>Current do not use date:</label>
									<div class="dnuDate">
										<p>
											{{
												moment(
													currentDoNotUseDate
												).format("MMMM Do YYYY")
											}}
										</p>
									</div>
								</div>

								<div
									class="form-input"
									v-if="
										timeUnits != null &&
										checkedStatus == 'Time-critical issue'
									"
								>
									<label>New do not use date:</label>
									<div class="dnuDate">
										<p>
											{{
												moment(
													new Date(doNotUseDate)
												).format("MMMM Do YYYY")
											}}
										</p>
									</div>
								</div>

								<div
									class="form-input dnu"
									v-if="
										checkedStatus == 'Time-critical issue'
									"
								>
									<div class="doNotUse">
										<p
											v-if="currentDoNotUseDate"
											class="text"
										>
											Change to
										</p>
										<p v-else>Do not use</p>
										<input
											class="input"
											type="number"
											v-model="timeUnits"
											max="99"
											min="1"
										/>
										<select
											class="time-frame"
											v-model="timeFrame"
										>
											<option value="days">days</option>
											<option value="months">
												months
											</option>
											<option value="years">years</option>
										</select>
										<p class="text">from now</p>
									</div>
								</div>

								<div class="form-input">
									<label>Retirement Date:</label>
									<div
										class="help-text danger"
										v-if="
											retirementDate &&
											doNotUseDate >
												new Date(retirementDate)
										"
									>
										Do not use date greater than retirement
										date
									</div>
									<input
										class="constrain-input"
										type="date"
										v-model="retirementDate"
									/>
								</div>
							</div>
							<div class="p-1rem">
								<div
									class="form-input"
									v-if="bulkData.length == 0"
								>
									<label>Search Tags:</label>
									<vue-select
										@submit.prevent="handelSubmit"
										v-if="tags"
										v-model="tagName"
										:options="loadedTags"
										searchable
										:clear-on-select="true"
										:clear-on-close="true"
										:close-on-select="true"
										@search:input="handleTagInput"
										search-placeholder="Type & select to add new tags"
										placeholder="Add Search Tags"
										@blur="addTag(tagName)"
									></vue-select>
									<div class="spacer-20"></div>
									<div class="pill-container">
										<div v-for="tag in tags" :key="tag">
											<div class="pill">
												<fa class="fa-tag" icon="tag" />
												{{ tag }}
												<fa
													class="fa-times"
													icon="times"
													@click="deleteTag(tag)"
												/>
											</div>
										</div>
									</div>
								</div>
								<div
									class="form-input"
									v-if="bulkData.length != 0"
								>
									<label>Add Tags:</label>
									<vue-select
										@submit.prevent="handelSubmit"
										v-model="tagTobeAdded"
										:options="loadedTags"
										searchable
										:clear-on-select="true"
										:clear-on-close="true"
										:close-on-select="true"
										@search:input="handleTagInput"
										search-placeholder="Type & select to add new tags"
										placeholder="Add Search Tags"
										@blur="addTags(tagTobeAdded, true)"
									></vue-select>
									<div v-if="addTagsList.length">
										<div class="spacer-20"></div>
										<div class="pill-container">
											<div
												v-for="tag in addTagsList"
												:key="tag"
											>
												<div class="pill">
													<fa
														class="fa-tag"
														icon="tag"
													/>
													{{ tag }}
													<fa
														class="fa-times"
														icon="times"
														@click="
															deleteTagAdded(
																tag,
																true
															)
														"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									class="form-input"
									v-if="bulkData.length != 0"
								>
									<label>delete Tags:</label>
									<vue-select
										@submit.prevent="handelSubmit"
										v-model="tagTobeDeleted"
										:options="loadedTags"
										searchable
										:clear-on-select="true"
										:clear-on-close="true"
										:close-on-select="true"
										@search:input="handleTagInput"
										search-placeholder="Type & select to add new tags"
										placeholder="Add Search Tags"
										@blur="addTags(tagTobeDeleted, false)"
									></vue-select>
									<div class="spacer-20"></div>
									<div class="pill-container">
										<div
											v-for="tag in deleteTagList"
											:key="tag"
										>
											<div class="pill">
												<fa class="fa-tag" icon="tag" />
												{{ tag }}
												<fa
													class="fa-times"
													icon="times"
													@click="
														deleteTagAdded(
															tagTobeDeleted,
															false
														)
													"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/reactivity";
import {
	computed,
	onBeforeMount,
	onMounted,
	watchEffect,
} from "@vue/runtime-core";
import {
	projectFirestore,
	timestamp,
	timestampFromDate,
} from "../../firebase/config";
import getDocumentOnce from "../../composables/getDocumentOnce";
import { useRouter } from "vue-router";
import account from "../../composables/account";
import getCollection from "../../composables/getCollection";
import useDocument from "../../composables/useDocument";
import PageLoading from "../../components/PageLoading.vue";
import Alert from "../../components/Alert.vue";
import moment from "moment";
import { useMeta } from "vue-meta";
import equipmentCollection from "../../composables/equipmentsCollection";
import { inject } from "vue";

export default {
	components: { PageLoading, Alert },
	name: "EquipmentEdit",
	props: ["id"],
	setup(props) {
		useMeta({ title: "Add Equipment" });

		const bulkDataAray = inject("bulkDataAray");

		var bulkData = [];
		if (bulkDataAray.value != undefined) {
			if (bulkDataAray.value.length > 0) {
				bulkData = bulkDataAray.value;
			}
		}

		//  Account & permissions
		const { companyId, user, userDoc } = account();

		const {
			error: err,
			updateEquipmentTag,
			updateEquipmentNames,
			getEquipmentTags,
			getEquipmentNames,
			bulkEquipmentUpdate,
		} = equipmentCollection();
		const router = useRouter();
		const error = ref(null);

		// Data
		const db = projectFirestore;
		const collectionRef = db.collection("companies").doc(companyId);
		const equipmentRef = collectionRef.collection("equipment");

		// This is used to check if the equipment id is unique
		const { documents: equipment } = getCollection(equipmentRef);

		const loadedTags = ref([]);
		const equipmentNames = ref([]);
		const locations = ref(null);
		const locationNamesList = [];
		const equipmentNamesList = [];
		let prevLocationName = "";
		let max_equipments;

		const timeFrame = ref("months");
		const timeUnits = ref(null);
		const addTagsList = ref([]);
		const tagTobeAdded = ref("");
		const deleteTagList = ref([]);
		const tagTobeDeleted = ref("");

		//   Form refs
		const equipmentName = ref("");
		const identifier = ref("");
		const location = ref(null);
		const color = ref("");
		const tags = ref([]);
		const tagName = ref([]);
		const initials = ref("");
		const checkedStatus = ref("Unchecked");
		const usedEquipments = ref(0);
		const canAddEquipments = ref(true);
		const checkedStatusList = [
			"Safe for use",
			"Time-critical issue",
			"Do not use",
			"Unchecked",
			"Missing",
		];
		const notes = ref("");
		const doNotUseDate = ref(null);
		const currentDoNotUseDate = ref(null);

		const retirementDate = ref(null);

		const locationPlaceholder = ref();
		const namePlaceholder = ref();

		const isUpdate = ref(false);

		if (props.id != "new" && props.id != "bulk-update") {
			isUpdate.value = true;
			locationPlaceholder.value = "Change location";
			namePlaceholder.value = "Change Item Name";
		}

		if (props.id == "bulk-update") {
			locationPlaceholder.value = "Select location";
			namePlaceholder.value = "Select or add name";
		}

		const item = ref(null);

		onBeforeMount(async () => {
			checkedStatus.value = "";

			// If it's bulk update or new item, stop
			if (props.id === "bulk-update" || props.id === "new") return;

			const updateRef = equipmentRef.doc(props.id);

			const { document } = await getDocumentOnce(updateRef);

			item.value = document.value;

			equipmentName.value = item.value.name;
			identifier.value = item.value.identifier;

			color.value = item.value.color;
			initials.value = item.value.initials;
			tags.value = item.value.tags;
			// createdAt.value = item.value.createdAt;
			if (item.value.doNotUseDate) {
				currentDoNotUseDate.value = item.value.doNotUseDate.toDate();
			}

			if (item.value.retirementDate) {
				retirementDate.value = moment(
					item.value.retirementDate.toDate()
				).format("YYYY-MM-DD");
			}

			notes.value = item.value.notes;
			checkedStatus.value = item.value.checkedStatus;
			locationPlaceholder.value = item.value.locationName;
			namePlaceholder.value = item.value.name;
		});

		const identifierExists = ref(false);
		const isLoading = ref(false);
		const validateName = ref(null);

		const saveOptions = [
			"Save & close",
			"Save & duplicate",
			"Save & clear",
		];

		const saveOptionSelected = ref("Save & close");

		const validSubscription = ref(false);

		const companyDoc = ref(null);

		// Get locations, equipment and tags
		onMounted(async () => {
			const { document } = await getDocumentOnce(collectionRef);

			companyDoc.value = document.value;

			if (companyDoc.value) {
				// Check for valid sub status
				if (
					companyDoc.value.subscription.status == "active" ||
					companyDoc.value.subscription.status == "trialing" ||
					companyDoc.value.subscription.status == "free"
				) {
					validSubscription.value = true;
				}

				loadedTags.value = await getEquipmentTags();
				max_equipments = companyDoc.value.subscription.max_equipments;

				if (!loadedTags.value) {
					loadedTags.value = [];
				}

				equipmentNames.value = await getEquipmentNames();

				if (!equipmentNames.value) {
					equipmentNames.value = [];
				}

				equipmentNames.value.forEach((name) => {
					equipmentNamesList.push(name);
				});

				locations.value = [];

				// Add locations the user has access to
				companyDoc.value.locations.forEach((location) => {
					if (userDoc.value.locations.includes(location.locationId)) {
						locations.value.push(location);
					}
				});

				locations.value.forEach((location) => {
					locationNamesList.push(location.locationName);
				});

				if (item.value != null) {
					location.value = {
						locationName: item.value.locationName,
						locationId: item.value.locationId,
					};
				}

				if (location.value != null) {
					addTag(location.value.locationName);
					prevLocationName = location.value.locationName;
				}
			} else {
				error.value = "Could not get data";
			}
		});

		// Form validation
		const validateIdentifier = computed(() => {
			return identifier.value.split(/\s+/).length > 1;
		});
		const validateDotIdentifier = computed(() => {
			return identifier.value.split(/\./).length > 1;
		});
		const validateSlashIdentifier = computed(() => {
			return identifier.value.split(/\//).length > 1;
		});
		const validateMultipleDotIdentifier = computed(() => {
			return identifier.value.split(/\../).length > 1;
		});

		// check the identifier is unique
		watchEffect(() => {
			if (equipment.value && equipment.value != null) {
				usedEquipments.value = equipment.value.length;

				let matchingIds = equipment.value.filter((e) => {
					return (
						e.identifier.toLowerCase() ===
						identifier.value.toLowerCase()
					);
				});
				if (matchingIds.length == 1 && !isUpdate.value) {
					identifierExists.value = true;
				} else {
					identifierExists.value = false;
				}
			}
		});

		const formDisabled = computed(() => {
			if (bulkData.length > 0) {
				return false;
			} else {
				return (
					!equipmentName.value ||
					!identifier.value ||
					!location.value ||
					!color.value
				);
			}
		});

		const addInitials = computed(() => {
			if (equipmentName.value) {
				const nameWords = equipmentName.value
					.replace(/\s+/g, " ")
					.split(" ");

				if (nameWords.length == 1) {
					initials.value = nameWords.shift().charAt(0);
				} else {
					initials.value =
						nameWords.shift().charAt(0) + nameWords.pop().charAt(0);
				}

				return initials.value.toUpperCase();
			}
		});

		// Search/add equipment names dropdown
		const prevNameInput = ref("");
		function removeTemporaryNameOption(inputEvent) {
			if (equipmentName.value.includes(prevNameInput.value) === false) {
				equipmentNames.value = equipmentNames.value.filter(
					(option) => option !== prevNameInput.value
				);
			}

			prevNameInput.value = inputEvent.target.value;
		}

		function addTemporaryNameOption(inputEvent) {
			const input = inputEvent.target.value;
			if (input === "") return;
			if (input.split(/\s+/).length <= 3) validateName.value = false;
			if (input.split(/\s+/).length > 3)
				return (validateName.value = true);
			if (equipmentNames.value.includes(input)) return;

			equipmentNames.value.push(input);
		}

		function handleNameInput(inputEvent) {
			removeTemporaryNameOption(inputEvent);
			addTemporaryNameOption(inputEvent);
		}

		// Search tags dropdown
		const prevTagInput = ref("");
		function removeTemporaryTagOption(inputEvent) {
			if (tagName.value.includes(prevTagInput.value) === false) {
				loadedTags.value = loadedTags.value.filter(
					(option) => option !== prevTagInput.value
				);
			}

			prevTagInput.value = inputEvent.target.value;
		}

		function addTemporaryTagOption(inputEvent) {
			const input = inputEvent.target.value;
			if (input === "") return;
			if (loadedTags.value.includes(input)) return;

			loadedTags.value.push(input);
		}

		function handleTagInput(inputEvent) {
			removeTemporaryTagOption(inputEvent);
			addTemporaryTagOption(inputEvent);
		}

		//    Only add the currently selected equipment name
		let prevEquipmentName = null;
		const removeOldTag = (newTag) => {
			if (!prevEquipmentName) {
				prevEquipmentName = newTag;
				return;
			}

			if (prevEquipmentName === newTag) {
				return;
			}

			if (prevEquipmentName && newTag !== prevEquipmentName) {
				tags.value = tags.value.filter((t) => {
					return t !== prevEquipmentName;
				});

				prevEquipmentName = newTag;
			}
		};

		//    Only add the currently selected location name
		const removeOldLocation = (newTag) => {
			// If no old location name, set it to the new location name
			if (!prevLocationName) {
				prevLocationName = newTag;
				return;
			}

			// If the old location name is the same as the new location name, return
			if (prevLocationName === newTag) {
				return;
			}

			// If the old location name is not the same as the new location name,
			// remove the old location name from the tags
			if (prevLocationName && newTag !== prevLocationName) {
				tags.value = tags.value.filter((t) => {
					return t !== prevLocationName;
				});

				prevLocationName = newTag;
			}
		};

		const addTags = (tag, isAdd) => {
			if (tag != null && tag != "") {
				if (isAdd) {
					addTagsList.value.push(tag);
				} else {
					deleteTagList.value.push(tag);
				}
			}
		};

		const deleteTagAdded = (tag, isAdd) => {
			if (isAdd) {
				addTagsList.value = addTagsList.value.filter((item) => {
					return item !== tag;
				});
			} else {
				deleteTagList.value = deleteTagList.value.filter((item) => {
					return item !== tag;
				});
			}
		};

		// Add search tags
		const addTag = (tag) => {
			if (tag != "" && tag != null) {
				tags.value.push(tag);

				let unique = [...new Set(tags.value)];

				tags.value = unique;

				if (
					!equipmentNamesList.includes(tag) &&
					!locationNamesList.includes(tag) &&
					equipmentName.value != tag
				) {
					updateEquipmentTag(tag);
				}
			}
		};

		const deleteTag = (tag) => {
			tags.value = tags.value.filter((item) => {
				return item !== tag;
			});
		};

		watchEffect(() => {
			if (
				checkedStatus.value == "Time-critical issue" &&
				timeUnits.value > 0 &&
				timeFrame.value != ""
			) {
				const date = new Date();

				// remove the s from days, months, years
				const tF = timeFrame.value.substring(
					0,
					timeFrame.value.length - 1
				);

				const nDate = moment(date).add(timeUnits.value, tF).valueOf();

				const newDate = nDate;

				doNotUseDate.value = new Date(newDate);
			} else if (item.value) {
				doNotUseDate.value = item.value.doNotUseDate;
			}
		});

		const archive = async () => {
			isLoading.value = true;
			error.value = null;

			const docRef = equipmentRef.doc(identifier.value);

			const { updateDoc } = useDocument(docRef);

			const updates = {
				archived: true,
			};

			try {
				await updateDoc(updates);
			} catch (e) {
				error.value = "Could not archive item";
				isLoading.value = false;
			}

			router.push({
				name: "ArchivedEquipment",
			});
		};

		const archiveAll = ref(false);

		const handleArchiveAll = () => {
			archiveAll.value = true;

			handelSubmit(true);
		};

		const handelSubmit = async (forAchive) => {
			isLoading.value = true;
			error.value = null;

			if (!validSubscription.value) {
				error.value = "Invalid Subscription";
				setTimeout(() => {
					error.value = null;
					isLoading.value = false;
				}, 3000);

				return;
			}

			// Free (and other) subscriptions can have 50 equipment items per pack
			let equipmentMultiple = 50;

			const isUnlimited =
				companyDoc.value.subscription.unlimited ?? false;

			// active subscriptions can have 500 equipment items per pack
			if (
				["active", "past_due"].includes(
					companyDoc.value.subscription.status
				) ||
				isUnlimited
			) {
				equipmentMultiple = 500;
			}

			// Checks that the company has equipment available with their subscription
			if (
				!forAchive &&
				usedEquipments.value > max_equipments * equipmentMultiple &&
				!isUpdate.value
			) {
				canAddEquipments.value = false;
				isLoading.value = false;
				return;
			} else {
				canAddEquipments.value = true;
			}

			var createdAt = timestamp();
			var archived = false;

			if (doNotUseDate.value == undefined) {
				doNotUseDate.value = null;
			}

			if (retirementDate.value == undefined) {
				retirementDate.value = null;
			}

			if (checkedStatus.value == undefined) {
				checkedStatus.value = null;
			}

			if (notes.value == undefined) {
				notes.value = null;
			}

			if (bulkData.length > 0) {
				const setEquipment = {};

				setEquipment.author = user.value.uid;

				if (equipmentName.value != "") {
					setEquipment.name = equipmentName.value;
				}
				if (location.value != undefined) {
					setEquipment.locationName = location.value.locationName;
					setEquipment.locationId = location.value.locationId;
				}
				if (color.value != "") setEquipment.color = color.value;

				if (tags.value) {
					setEquipment.tags = tags.value;
				}
				if (notes.value != "") {
					setEquipment.notes = notes.value;
				}

				setEquipment.lastUpdated = timestamp();
				setEquipment.author = user.value.uid;

				if (checkedStatus.value != null && checkedStatus.value != "") {
					setEquipment.checkedStatus = checkedStatus.value;
				}

				if (doNotUseDate.value != null) {
					setEquipment.doNotUseDate = doNotUseDate.value;
				}

				// Default the retired flag to false
				setEquipment.retired = false;

				// always do this last as it will override the manual checked status
				if (retirementDate.value != null) {
					const now = new Date();
					const date = new Date(retirementDate.value);
					const timestamp = timestampFromDate(date);

					setEquipment.retirementDate = timestamp;

					if (date < now) {
						setEquipment.retired = true;
						setEquipment.checkedStatus = "Do not use";
					}
				}

				await bulkEquipmentUpdate(
					setEquipment,
					bulkData,
					addTagsList,
					deleteTagList,
					archiveAll.value
				);
				if (!err.value) {
					close();
					isLoading.value = false;
				}
			} else {
				if (isUpdate.value) {
					createdAt = item.value.createdAt;
					archived = item.value.archived;
				}

				const now = new Date();

				let date = null;

				if (retirementDate.value) {
					date = new Date(retirementDate.value);
				}

				let retired = false;

				if (retirementDate.value && date < now) {
					retired = true;
					checkedStatus.value = "Do not use";
				}

				const setEquipment = {
					name: equipmentName.value,
					identifier: identifier.value,
					locationName: location.value.locationName,
					locationId: location.value.locationId,
					color: color.value,
					initials: initials.value.toUpperCase(),
					tags: tags.value,
					createdAt: createdAt,
					lastUpdated: timestamp(),
					archived: archived,
					author: user.value.uid,
					doNotUseDate: doNotUseDate.value,
					retirementDate: date ? timestampFromDate(date) : null,
					retired: retired,
					notes: notes.value,
					checkedStatus: checkedStatus.value,
				};

				let batch = db.batch();

				let setItemRef = equipmentRef.doc(identifier.value);
				batch.set(setItemRef, setEquipment);

				batch
					.commit()
					.then(() => {
						close();
					})
					.catch((err) => {
						error.value = "Could not create item";
					});
			}

			updateEquipmentNames(equipmentName.value);
		};

		function clearFormRefs() {
			isUpdate.value = false;
			doNotUseDate.value = null;
			retirementDate.value = null;
			checkedStatus.value = "Unchecked";
			equipmentName.value = "";
			color.value = "";
			tags.value = [location.value.locationName];
			item.value = null;
			identifier.value = "";
			initials.value = "";
		}

		const close = () => {
			if (saveOptionSelected.value == "Save & clear") {
				clearFormRefs();
				router.push({
					name: "EquipmentEdit",
					params: { id: "new" },
				});
			}
			if (saveOptionSelected.value == "Save & close") {
				if (props.id == "bulk-update") {
					router.push({ name: "EquipmentDash" });
				} else {
					router.go(-1);
				}
			}
			setTimeout(() => {
				isLoading.value = false;
				if (
					saveOptionSelected.value == "Save & duplicate" &&
					isUpdate.value
				) {
					isUpdate.value = false;
				}
			}, 300);
			isLoading.value = false;
		};

		return {
			identifier,
			location,
			color,
			tags,
			validateIdentifier,
			validateDotIdentifier,
			validateSlashIdentifier,
			validateMultipleDotIdentifier,
			validateName,
			formDisabled,
			handelSubmit,
			identifierExists,
			locations,
			deleteTag,
			addInitials,
			isLoading,
			equipmentName,
			equipmentNames,
			tagName,
			loadedTags,
			addTag,
			//   addLocation,
			error,
			handleTagInput,
			handleNameInput,
			removeOldTag,
			removeOldLocation,
			notes,
			doNotUseDate,
			retirementDate,
			checkedStatus,
			checkedStatusList,
			timeUnits,
			timeFrame,
			canAddEquipments,
			isUpdate,
			moment,
			currentDoNotUseDate,
			locationPlaceholder,
			namePlaceholder,
			saveOptions,
			saveOptionSelected,
			archive,
			addTags,
			tagTobeAdded,
			deleteTagAdded,
			addTagsList,
			deleteTagList,
			tagTobeDeleted,
			bulkData,
			handleArchiveAll,
		};
	},
};
</script>

<style lang="scss" scoped>
.save-options {
	border: none;
	cursor: pointer;
	font-weight: bold;
	font-size: 14px;
	text-align: center;
	&:focus {
		outline: none;
	}
	@include respond(small) {
		font-size: 1em;
	}
}
.save-option {
	display: flex;
	align-items: center;
	margin-right: 0.2em;

	@include respond(medium) {
		margin-right: 1em;
		gap: 1rem;
	}
	.save-option-dropdown {
		border: 1px solid $rm-grey;
		font-size: 1em;
		padding: 0.3em 0.8em;
		border-radius: 1em;
		width: 170px;
	}
}
.dnu {
	margin-top: -15px !important;
}
.doNotUse {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0 5px 0 15px;
	margin-top: 25px;
	gap: 1em;
	.text {
		width: auto;
		text-align: center;
	}
	.time-frame {
		max-width: 100px;
	}
	input[type="number"] {
		width: 3em;
		height: 42px;
		text-align: center !important;
		padding: 0px !important;
	}
}
.dnuDate {
	display: flex;
	align-items: center;
	background-color: #f1f1f1;
	padding: 0.8em 1em;
	border-radius: 5px;
	color: $rm-dark-grey;
	margin: 3px;
	// font-size: 0.9em;
	height: 3.1em;
	cursor: not-allowed;
}
.form-submit {
	justify-content: flex-end !important;
	height: min-content !important;
}
.split {
	flex-direction: column !important;
	gap: 1em !important;

	@include respond(small) {
		flex-direction: row !important;
		gap: 1rem !important;
	}
}
.pill-container {
	flex-wrap: wrap;
	overflow: auto;
	.pill {
		font-size: 0.8em;
	}
}
#searchTag {
	padding: 9px 10px;
	&:focus {
		padding: 15px 10px;
	}
}

.rm-form {
	// width: 60vw;
	width: 86vw;
	height: 80vh;
	overflow: auto;

	// @include respond(medium) {
	// 	width: 80vw;
	// }
	// @include respond(large) {
	// 	width: 70vw;
	// }
	// @include respond(larger) {
	// 	width: 90vw;
	// }
	.form-input {
		// margin-bottom: 1.5em;
		.danger {
			color: $rm-danger !important;
		}
		.help-text {
			color: $rm-grey;
			font-size: 0.8em;
			padding-left: 10px;
			padding-bottom: 5px;
			font-style: italic;
		}
		.constrain-input {
			height: 3.1em;
			margin: 4px;
			width: 98%;
			background-color: #f7f7f7;
		}
	}
}

.modal .modal-container .modal-content .modal-header {
	box-shadow: none;
	.right {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
.modal-header {
	flex-direction: column !important;

	@include respond(small) {
		flex-direction: row !important;
	}
}
.modal-title {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	// width: 35ch;
	overflow: hidden;
	//  padding: 0 1rem;
	@include respond(medium) {
		width: 35ch;
	}
	h3 {
		// margin-left: 1em;
		margin-left: 0.5em;

		@include respond(medium) {
			margin-left: 1em;
		}
	}
}
.btn-primary {
	min-width: 65px !important;

	@include respond(small) {
		min-width: 100px !important;
	}
}
.muted {
	font-size: 14px !important;

	@include respond(small) {
		font-size: 1em !important;
	}
}
.notes {
	display: none;
}
.color-grid--equipment {
	display: grid;
	grid-template-columns: repeat(8, auto);
	grid-template-rows: auto;
	column-gap: 1rem;
	row-gap: 1rem;
	margin-left: 5px;
}
</style>
