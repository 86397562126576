<template>
    <Sidebar />
    <!-- <transition name="fade" mode="out-in">
        <router-view></router-view>
    </transition>-->
</template>

<script>
import Sidebar from "../../components/Sidebar.vue";

export default {
    name: "Equipment",
    components: { Sidebar },
};
</script>

<style>
</style>