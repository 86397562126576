<template>
	<Alert :message="error" v-if="error" />
	<!-- Images -->
	<div class="row">
		<div class="upload-container">
			<page-loading v-if="uploading" />

			<!-- Add new image button -->
			<div
				class="btn btn-icon"
				v-if="access && !upload && !archived && !uploading"
				@click="showImageUpload"
			>
				<div class="text">Add Image</div>
				<fa icon="image" />
			</div>

			<!-- Add new doc form -->
			<div class="file-container" v-if="upload && !uploading">
				<!-- <form @submit.prevent="handleImageUpload">
					<button type="submit" class="btn-icon muted">
						<div class="text">Upload</div>
						<fa icon="save" />
					</button>
					
				</form> -->
				<input
					type="file"
					class="custom-file-input"
					@change="onImageUpload"
				/>

				<div class="btn-icon muted" @click="showImageUpload">
					<fa icon="times" />
				</div>
			</div>
		</div>
	</div>

	<div class="documents">
		<div class="image" v-if="filesEmpty">
			<div class="content">
				<fa class="img-icon" icon="folder-open" />
				<div class="title">No images...</div>
			</div>
		</div>
		<div v-for="file in images" :key="file.id">
			<div class="image">
				<fa
					class="btn-deleteImg"
					icon="times-circle"
					v-if="access"
					@click="handleDelete(file.id)"
				/>
				<div class="content" @click="openCarousel(file)">
					<img class="img-preview" :src="`${file.downloadUrl}`" />
					<!-- <div class="title">{{ file.fileName }}</div> -->
				</div>
			</div>
		</div>

		<div class="carousel" v-if="carouselVisible">
			<button class="btn-closeCarousel" @click="closeCarousel">
				<fa class="btn-icon" icon="times" />
			</button>
			<button class="btn-prev" @click="prevImage">
				<fa class="btn-icon" icon="arrow-left" />
			</button>
			<button class="btn-next" @click="nextImage">
				<fa class="btn-icon" icon="arrow-right" />
			</button>
			<div class="carousel-images">
				<img
					class="carousel-image"
					v-for="file in carouselImages"
					:key="file.id"
					:src="`${file.downloadUrl}`"
					:class="{ active: file.id === carouselImage.id }"
				/>
			</div>
			<div class="title">{{ carouselImage.fileName }}</div>
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Alert from "./Alert.vue";
import useStorage from "../composables/useStorage";
import useCollection from "../composables/useCollection";
import { projectFirestore } from "../firebase/config";
import getCollection from "../composables/getCollection";
import { computed, watchEffect } from "@vue/runtime-core";
import PageLoading from "./PageLoading.vue";
import { v4 as uuidv4 } from "uuid";
import account from "../composables/account";
import equipmentCollection from "../composables/equipmentsCollection";
import { async } from "q";
import userCollection from "../composables/userCollection";
export default {
	props: [
		"companyId",
		"collection",
		"assetId",
		"access",
		"archived",
		"equipmentId",
		"fileType",
	],
	components: { Alert, PageLoading },
	setup(props) {
		const companyId = props.companyId;
		const collection = props.collection;
		const assetId = props.assetId;
		const access = props.access;
		const archived = props.archived;
		const error = ref(null);

		const carouselVisible = ref(false);
		const carouselImage = ref(null);

		let id = uuidv4();
		const { userDoc } = account();
		// Data
		let db = projectFirestore;
		let companyRef = db.collection("companies").doc(companyId);
		let storageRef = companyRef
			.collection(collection)
			.doc(assetId)
			.collection("storage");

		const { documents: storage } = getCollection(storageRef);
		const { addEquipmentImage } = equipmentCollection();
		// select file
		const types = ["image/png", "image/jpeg"];
		const file = ref(null);

		const uploading = ref(false);
		const success = ref(null);
		const deleting = ref(null);

		const upload = ref(false);
		const showImageUpload = () => {
			if (upload.value) {
				upload.value = false;
			} else {
				upload.value = true;
			}
		};

		const filesEmpty = computed(() => {
			if (storage.value) {
				return storage.value.length == 0;
			}
		});
		const images = computed(() => {
			if (storage.value) {
				return storage.value.filter(
					(file) => !file.isArchived && file.fileType === "image"
				);
			} else {
				return [];
			}
		});
		// Select file
		const onImageUpload = (e) => {
			error.value = null;

			const selected = e.target.files[0];
			if (
				selected &&
				types.includes(selected.type) &&
				selected.size < 5000000
			) {
				file.value = selected;
				error.value = null;

				handleImageUpload();
			} else {
				file.value = null;
				error.value = "Image must be png/jpg and less than 1mb";
			}
		};

		// Upload file
		const handleImageUpload = async () => {
			error.value = null;
			uploading.value = true;
			success.value = null;

			if (file.value) {
				const {
					uploadFile,
					downloadUrl,
					error: uploadErr,
				} = useStorage();
				var docType = "images";
				let filePath = `${companyId}/${collection}/${assetId}/${docType}/${file.value.name}`;

				await uploadFile(filePath, file.value);

				if (uploadErr.value) {
					error.value = uploadErr.value;
					uploading.value = false;
					return;
				}

				const { addDoc, error: addDocErr } = useCollection(storageRef);
				console.log(props, "hii");
				if (downloadUrl.value) {
					if (props.equipmentId != undefined) {
						id = uuidv4();
						let fileImage = {
							fileName: file.value.name,
							filePath: filePath,
							downloadUrl: downloadUrl.value,
							fileType: props.fileType,
							isArchived: false,
							ownerId: userDoc.value.id,
							id: id,
						};
						await addEquipmentImage(
							props.equipmentId,
							id,
							fileImage
						);
					} else {
						let fileImage = {
							fileName: file.value.name,
							filePath: filePath,
							downloadUrl: downloadUrl.value,
						};
						await addDoc(fileImage);
					}
				}

				if (addDocErr.value) {
					error.value = "Failed to upload file";
				}

				if (!error.value) {
					success.value = "File uploaded";
					uploading.value = false;
					setTimeout(() => {
						success.value = null;
					}, 1000);
				}
			} else {
				uploading.value = false;
				error.value = "No file selected";
			}
		};

		const handleDelete = async (docId) => {
			const { updateDoc } = useCollection(storageRef);
			await updateDoc(docId, { isArchived: true });
		};

		const carouselImages = computed(() => {
			const allImages = images.value.filter(
				(file) => file.fileType === "image"
			);
			const currentIndex = allImages.findIndex(
				(file) => file.id === carouselImage.value?.id
			);
			if (currentIndex === -1) {
				return allImages;
			}
			const postIndex = allImages.slice(currentIndex);
			const preIndex = allImages.slice(0, currentIndex);
			return [...postIndex, ...preIndex];
		});

		const openCarousel = (file) => {
			carouselImage.value = file;
			carouselVisible.value = true;
		};

		const closeCarousel = () => {
			carouselVisible.value = false;
		};

		const prevImage = () => {
			const currentIndex = carouselImages.value.findIndex(
				(file) => file.id === carouselImage.value.id
			);
			let prevIndex = currentIndex - 1;
			if (prevIndex < 0) {
				prevIndex = carouselImages.value.length - 1;
			}
			carouselImage.value = carouselImages.value[prevIndex];
		};

		const nextImage = () => {
			const currentIndex = carouselImages.value.findIndex(
				(file) => file.id === carouselImage.value.id
			);
			let nextIndex = currentIndex + 1;
			if (nextIndex >= carouselImages.value.length) {
				nextIndex = 0;
			}
			carouselImage.value = carouselImages.value[nextIndex];
		};
		return {
			upload,
			showImageUpload,
			file,
			uploading,
			success,
			deleting,
			onImageUpload,
			handleImageUpload,
			handleDelete,
			storage,
			access,
			error,
			filesEmpty,
			archived,
			images,
			carouselVisible,
			carouselImage,
			openCarousel,
			closeCarousel,
			carouselImages,
			prevImage,
			nextImage,
		};
	},
};
</script>

<style lang="scss" scoped>
.documents {
	display: grid;
	grid-template-columns: repeat(5, 100px);
	grid-template-rows: min-content;
	column-gap: 1em;
	row-gap: 1em;
	// display: flex;
	// gap: 3px;
}

.image {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	border-radius: 8px;
	border: 1px solid $rm-light-grey;
	width: 100px;
	height: 100px;
	overflow: hidden;
	cursor: pointer;

	.btn-deleteImg {
		display: none;
	}

	&:hover {
		box-shadow: $box-shadow;

		.btn-deleteImg {
			display: block;
			position: absolute;
			top: 3px;
			right: 3px;
			color: $rm-dark-blue;
			font-size: 1.2em;
			background-color: white;
			border: 2px solid $rm-grey;
			border-radius: 50%;
			z-index: 10;
			cursor: pointer;
			&:hover {
				color: $rm-danger;
			}
		}
	}

	.content {
		position: relative;
		text-align: center;

		.img-icon {
			font-size: 1.8em;
			margin-bottom: 0.6em;
		}

		.title {
			font-size: 0.8em;
			width: 70px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.file-container input {
	@include respond(large) {
		width: 30ch;
	}
}

.img-preview {
	min-width: 100%;
	min-height: 100%;
	max-width: 180px;
}

.carousel {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
	padding: 20px;
	max-width: 28%;
	max-height: 70%;
	overflow: auto;
}

.carousel .btn-closeCarousel {
	position: absolute;
	top: 5px;
	right: 0;
	font-size: 1rem;
	color: #666;
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}

.carousel .carousel-image {
	display: block;
	margin-right: 10px;
	flex-shrink: 0;
	width: 400px;
	height: 400px;
	object-fit: cover;
}

.carousel-images {
	display: flex;
	overflow-x: hidden;
	margin-bottom: 10px;
}

.carousel .title {
	text-align: center;
	margin-top: 10px;
}

.btn-prev,
.btn-next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 40px;
	height: 40px;
	background-color: white;
	border: 1px solid #ddd;
	border-radius: 50%;
	outline: none;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.btn-prev:hover,
.btn-next:hover {
	background-color: #ddd;
}

.btn-prev {
	left: 20px;
}

.btn-next {
	right: 20px;
}

.upload-container {
	width: 100%;
	text-align: right;
}
</style>
