<template>
	<div class="right-nav">
		<div class="page-title">
			<h2>Equipment</h2>
		</div>
		<div class="page-links" v-if="access">
			<ul>
				<li>
					<router-link :to="{ name: 'EquipmentDash' }"
						>Active</router-link
					>
				</li>
				<li>
					<router-link :to="{ name: 'ArchivedEquipment' }"
						>Archived</router-link
					>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: "LocationsDashNav",
	props: ["access"],
};
</script>

<style lang="scss" scoped>
.add-icon-link {
	display: inline-flex !important;
	align-items: center !important;
	width: auto !important;
}
</style>
