<template>
	<transition name="fade" mode="out-in">
		<Popup>
			<template #modal-header-content>
				<div class="modal-title">
					<!-- <h3>Add New Action</h3> -->
					<h3>
						{{ mode === "Add" ? "Add New Action" : "Edit Action" }}
					</h3>
				</div>
				<div class="btn-icon muted" @click="closeModal">
					close
					<fa icon="times" />
				</div>
			</template>
			<template #modal-body-content>
				<form
					id="modal-form"
					class="rm-form"
					@submit.prevent="handleSubmit"
				>
					<div class="spacer-20"></div>
					<div class="form-input">
						<textarea
							required
							v-model="newAction.actionDesc"
							placeholder="Action description..."
						></textarea>
					</div>
					<div class="form-input">
						<select v-model="newAction.state">
							<option value="Critical">Critical</option>
							<option value="Time-Critical">Time-Critical</option>
							<option value="Non Critical">Non Critical</option>
						</select>
					</div>
					<div class="form-input">
						<select v-model="newAction.assignedPerson">
							<option
								v-for="user in usersArray"
								:key="user.id"
								:value="user.id"
							>
								{{ user.name }}
							</option>
						</select>
					</div>
					<div class="form-input">
						<input
							class="date-input"
							type="date"
							v-model="newAction.assignedDate"
						/>
					</div>
					<div class="form-input">
						<select v-model="newAction.status">
							<option value="Not Started">Not Started</option>
							<option value="In Progress">In Progress</option>
							<option value="Complete">Complete</option>
						</select>
					</div>
					<div class="form-submit">
						<page-loading v-if="isLoading" />
						<button
							class="btn btn-primary"
							@click="addAction"
							:disabled="isActionEmpty || isAssignedDateEmpty"
						>
							Save
						</button>
					</div>
				</form>
			</template>
		</Popup>
	</transition>
</template>

<script>
import { ref, computed, onMounted } from "@vue/runtime-core";
import equipmentCollection from "../../composables/equipmentsCollection";
import { useRouter, useRoute } from "vue-router";
import { v4 as uuidv4 } from "uuid";
import account from "../../composables/account";
import Popup from "../Popup.vue";
export default {
	name: "AddEditEquipmentAction",
	components: { Popup },
	setup() {
		const route = useRoute();
		var selectedAction = {};
		if (route.query.selectedAction != undefined) {
			if (route.query.selectedAction.length > 0) {
				selectedAction = JSON.parse(route.query.selectedAction);
			}
		}

		const newAction = ref({
			actionDesc: "",
			state: "Critical",
			assignedPerson: "",
			assignedDate: "Due Date",
			status: "Not Started",
		});

		const id = uuidv4();
		const { userDoc } = account();
		const usersArray = ref([]);

		const router = useRouter();

		const { addEquipmentAction, updateEquipmentAction, getEmployees } =
			equipmentCollection();

		const isActionEmpty = computed(() => {
			return (
				!newAction.value.actionDesc ||
				!newAction.value.actionDesc.trim()
			);
		});
		const isAssignedDateEmpty = computed(() => {
			return (
				!newAction.value.assignedDate ||
				isNaN(new Date(newAction.value.assignedDate).getTime())
			);
		});
		const fetchUsers = async () => {
			try {
				const employees = await getEmployees(route.query.companyId);
				usersArray.value = employees;
				if (selectedAction.assignedPerson) {
					newAction.value.assignedPerson =
						selectedAction.assignedPerson.id;
				} else {
					newAction.value.assignedPerson = employees[0].id;
				}
			} catch (error) {
				console.log("Error while fetching users", error);
			}
		};

		const addAction = () => {
			const selectedPerson = usersArray.value.find(
				(user) => user.id === newAction.value.assignedPerson
			);
			const assignedPerson = {
				id: selectedPerson.id,
				name: selectedPerson.name,
			};
			const action = {
				actionDesc: newAction.value.actionDesc,
				status: newAction.value.status,
				state: newAction.value.state,
				assignedPerson: assignedPerson,
				assignedDate: newAction.value.assignedDate,
				companyId: route.query.companyId,
				equipmentName: route.query.equipmentName,
				identifier: route.query.identifier,
			};
			try {
				if (route.query.actionId === undefined) {
					addEquipmentAction(
						userDoc.value.id,
						id,
						action,
						route.query.equipmentName,
						route.query.identifier,
						route.query.companyId
					);
					newAction.value = {
						actionDesc: "",
						state: "",
						assignedPerson: "",
						assignedDate: "",
						status: "",
					};
					router.push({
						name: "EquipmentDetail",
						params: { id: route.query.equipmentId },
					});
				} else {
					updateEquipmentAction(route.query.actionId, action);
				}

				newAction.value = {
					actionDesc: "",
					state: "",
					assignedPerson: "",
					assignedDate: "",
					status: "",
				};
				if (route.query.equipmentActionRoute) {
					router.push({
						name: route.query.equipmentActionRoute,
						params: { id: route.query.equipmentId },
					});
				} else if (route.query.actionsRoute) {
					router.push({
						name: route.query.actionsRoute,
						params: { id: route.query.equipmentId },
					});
				}
			} catch (error) {
				console.log("Error while adding action", error);
			}
		};

		const closeModal = () => {
			if (route.query.mode === "Edit") {
				if (route.query.equipmentActionRoute) {
					router.push({
						name: route.query.equipmentActionRoute,
						params: { id: route.query.equipmentId },
					});
				} else if (route.query.actionsRoute) {
					router.push({
						name: route.query.actionsRoute,
						params: { id: route.query.equipmentId },
					});
				}
			} else {
				router.push({
					name: "EquipmentDetail",
					params: { id: route.query.equipmentId },
				});
			}
		};
		onMounted(async () => {
			// console.log(props, 'hello')
			// console.log("Selected Action: ", selectedAction);
			if (selectedAction.id) {
				console.log(selectedAction.assignedDate, "assignedDate");
				newAction.value = {
					actionDesc: selectedAction.description,
					state: selectedAction.state,
					assignedPerson: selectedAction.assignedPerson,
					assignedDate: selectedAction.assignedDate,
					status: selectedAction.status,
				};
			}

			await fetchUsers();
			// console.log("New Action: ", newAction.value);
		});

		return {
			newAction,
			addAction,
			selectedAction,
			usersArray,
			isActionEmpty,
			isAssignedDateEmpty,
			closeModal,
		};
	},
};
</script>

<style lang="scss" scoped>
form {
	textarea {
		width: 100%;
		padding: 1em;
		font-family: "Poppins";
		color: $rm-dark-blue;
		font-size: 1em;
		background-color: $rm-light-grey;
		border: none;
		border-radius: 4px;
	}
}
</style>
