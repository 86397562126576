<template>
	<Alert :message="error" v-if="error" />

	<div class="right-content" v-if="viewChecklists">
		<div class="right-nav">
			<div class="flex">
				<!-- <div
					class="btn btn-icon"
					v-if="equipment"
					@click="showChecklists"
				>
					<fa icon="arrow-left" />
					<div class="text">Back</div>
				</div> -->

				<!-- <h2>
					<span class="back" @click="handleClick">Equipment</span>
					/
					<span
						class="back"
						v-if="equipment"
						@click="showChecklists"
						>{{ equipment.id }}</span
					>
					/
					<span>Checklists</span>
				</h2> -->
			</div>
			<div class="nav-links-alt">
				<div class="btn btn-icon" @click="showChecklists">
					<div class="text">Back to item details</div>
					<fa icon="arrow-right" />
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="page-detail">
				<ChecklistList
					status="complete"
					:companyId="companyId"
					:equipmentTag="equipmentId"
				/>
			</div>
		</div>
	</div>

	<!-- Content -->
	<div v-else class="right-content">
		<div class="right-nav">
			<div class="flex">
				<div class="btn btn-icon" v-if="equipment" @click="handleClick">
					<fa icon="arrow-left" />
					<div class="text">Back</div>
				</div>
				<!-- <h2>
					<span class="back" @click="handleClick">Equipment</span>
					<span v-if="equipment && equipment.archived"
						>/ Archived</span
					>
					/
					<span v-if="equipment">{{ equipment.id }}</span>
				</h2> -->
			</div>

			<div class="nav-links-alt" v-if="!downloading">
				<!-- Add new document div -->
				<div class="btn btn-icon" @click="downloadExcel" v-if="access">
					<div class="text">Download History</div>
					<fa icon="file-excel" />
				</div>

				<div class="btn btn-icon" @click="showChecklists">
					<div class="text">Search Checklists</div>
					<fa icon="search" />
				</div>
				<button
					v-if="equipment && !equipment.archived && access"
					class="btn btn-success btn-large rounded"
					@click="showForm(equipment.id)"
				>
					<div class="text">Update Item &nbsp; <fa icon="pen" /></div>
				</button>
				<button
					v-if="equipment && equipment.archived && access"
					class="btn btn-success btn-large rounded"
					@click="restore(equipment.id)"
				>
					<div class="text">
						Restore Item &nbsp; <fa icon="trash-restore" />
					</div>
				</button>
			</div>
			<div class="nav-links-alt" v-else>
				<div class="loading p-1rem">
					<page-loading />
				</div>
			</div>
		</div>

		<div class="page-content">
			<div class="page-container">
				<div class="split">
					<!-- Col 1 -->
					<div class="row item-container">
						<!-- Loading shimmer -->
						<div class="row" v-if="!equipment">
							<div class="left-title flex">
								<div class="circle-avatar shine"></div>
								<div class="lines shine"></div>
							</div>

							<div>
								<div class="table-row-shimmer shine"></div>
							</div>
						</div>
						<div v-else>
							<div class="row">
								<div class="flex">
									<div
										class="circle-avatar avatar-inline-left"
										id="initials"
										:style="`background-color:${equipment.color}`"
									>
										{{ equipment.initials }}
									</div>
									<h2>{{ equipment.name }}</h2>
								</div>
							</div>
							<div class="spacer-20"></div>
							<!-- Item Archived -->
							<div
								class="row tile rounded background-danger"
								v-if="equipment.archived"
							>
								<div class="item">
									<div class="item-icon">
										<!-- Do not use -->
										<fa class="icon" icon="archive" />
									</div>
									<b>ARCHIVED</b>
								</div>
							</div>
							<div v-else>
								<div
									class="row tile rounded background-danger"
									v-if="
										equipment.checkedStatus == 'Do not use'
									"
								>
									<div class="item">
										<div class="item-icon">
											<!-- Do not use -->
											<fa
												class="icon"
												icon="exclamation-triangle"
											/>
										</div>
										<b>{{ equipment.checkedStatus }}</b>
									</div>
								</div>
								<div v-else class="row tile rounded">
									<div class="item">
										<div class="item-icon">
											<!-- Unchecked & missing -->
											<fa
												v-if="
													!equipment.checkedStatus ||
													equipment.checkedStatus ==
														'Missing' ||
													equipment.checkedStatus ==
														'Unchecked'
												"
												class="icon"
												icon="question-circle"
											/>
											<!-- Safe for use -->
											<fa
												v-if="
													equipment.checkedStatus ==
													'Safe for use'
												"
												class="icon success"
												icon="check-circle"
											/>
											<!-- Time-critical -->
											<fa
												v-if="
													equipment.checkedStatus ==
													'Time-critical issue'
												"
												class="icon warning"
												icon="flag"
											/>
										</div>
										<b>{{ equipment.checkedStatus }}</b>
									</div>
									<div class="item">
										<div class="item-icon"></div>
										<i
											v-if="
												equipment.doNotUseDate &&
												equipment.checkedStatus ==
													'Time-critical issue'
											"
										>
											Do not use from
											{{
												moment(
													equipment.doNotUseDate.toDate()
												).format("MMMM Do yy")
											}}
										</i>
									</div>
								</div>
							</div>

							<!-- Item location -->
							<div class="row tile rounded">
								<div class="item">
									<div class="item-icon">
										<!-- <fa icon="tag" /> -->
										<b>ID</b>
									</div>
									<b>{{ equipment.identifier }}</b>
								</div>
							</div>
							<!-- Item location -->
							<div class="row tile rounded">
								<div class="item">
									<div class="item-icon">
										<fa icon="building" />
									</div>
									<b>{{ equipment.locationName }} </b>
								</div>
							</div>

							<!-- Item retirement date -->
							<div class="row tile rounded">
								<div class="item">
									<div class="item-icon">
										<fa icon="archive" />
									</div>
									<b v-if="equipment.retirementDate">
										{{
											moment(
												equipment.retirementDate.toDate()
											).format("MMMM Do yy")
										}}
									</b>
									<i v-else>Retirement Date not set</i>
								</div>
							</div>
							<div class="row">
								<div class="pill-container">
									<div
										v-for="tag in equipment.tags"
										:key="tag"
										class="pill"
									>
										<fa icon="tag" />
										{{ tag }}
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- Col 2 -->
					<div v-if="equipment">
						<!-- Tabs -->
						<div class="page-links">
							<!-- Item history: -->
							<div
								class="collection-title"
								@click="toggleSection('itemHistory')"
								:class="{
									active: activeSection === 'itemHistory',
								}"
							>
								<span>History</span>
							</div>

							<!-- Actions: -->
							<div
								class="collection-title"
								@click="toggleSection('actions')"
								:class="{ active: activeSection === 'actions' }"
							>
								<span>Actions</span>
							</div>

							<!-- Notes: -->
							<div
								class="collection-title"
								@click="toggleSection('notes')"
								:class="{ active: activeSection === 'notes' }"
							>
								<span>Notes</span>
							</div>

							<!-- Documents: -->
							<div
								class="collection-title"
								@click="toggleSection('documents')"
								:class="{
									active: activeSection === 'documents',
								}"
							>
								<span>Documents</span>
							</div>

							<!-- Images: -->
							<div
								class="collection-title"
								@click="toggleSection('images')"
								:class="{ active: activeSection === 'images' }"
							>
								<span>Images</span>
							</div>
						</div>
						<!-- Tabs End -->

						<!-- Notes -->
						<div
							class="log-item_container"
							v-if="activeSection === 'notes'"
						>
							<!-- Add new note button -->
							<div
								v-if="access && !equipment.archived"
								class="add-new-item"
							>
								<div class="btn btn-icon" @click="showNoteForm">
									<div class="text">Add Note</div>
									<fa icon="pen" />
								</div>
							</div>

							<!-- Notes List -->
							<div
								v-for="(note, index) in notes"
								:key="index"
								class="row log-item_tile"
							>
								<div class="user-header">
									{{ getUserName(note.ownerId) }} on
									{{ formatDateTime(note.updatedAt) }}
								</div>
								<div class="note-data">
									<div class="message">
										{{ note.content }}
									</div>
									<div class="edit-delete-btn">
										<div
											class="actions-icon-btn"
											@click="editNote(note)"
											v-if="access && !equipment.archived"
										>
											<fa icon="pen" />
										</div>
										<div
											class="actions-icon-btn"
											@click="showDeleteAlert(note.id)"
											v-if="access && !equipment.archived"
										>
											<fa icon="archive" />
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- Actions -->
						<div
							class="log-item_container"
							v-if="activeSection === 'actions'"
						>
							<!-- Add new action button -->
							<div
								v-if="access && !equipment.archived"
								class="add-new-item"
							>
								<div
									class="btn btn-icon"
									@click="showActionForm()"
								>
									<div class="text">Add Action</div>
									<fa icon="pen" />
								</div>
							</div>

							<!-- Actions list -->
							<div
								v-for="(action, index) in actions"
								:key="index"
								class="row log-item_tile"
							>
								<div class="user-header">
									<div
										v-if="action.status != 'Complete'"
										class="circle-avatar action-icon critical"
										:class="
											setActionColorClass(
												action.state,
												action.status
											)
										"
									>
										<fa
											class="ex-tri"
											icon="exclamation-triangle"
										/>
									</div>
									<div
										v-else
										class="circle-avatar action-icon complete"
									>
										<fa class="ex-tri" icon="check" />
									</div>
									<span v-if="action.status != 'Complete'">
										Assignee:
										{{
											getUserName(
												action.assignedPerson.id
											)
										}}
										| Due:
										{{ formatDateTime(action.createdAt) }}
									</span>
									<span v-else>
										Completed by
										{{
											getUserName(
												action.assignedPerson.id
											)
										}}
										on
										{{ formatDateTime(action.updatedAt) }}
									</span>
								</div>
								<div class="note-data">
									<div class="message">
										{{ action.description }}
									</div>
									<div class="edit-delete-btn">
										<div
											class="actions-icon-btn"
											@click="editAction(action)"
											v-if="access && !equipment.archived"
										>
											<fa icon="pen" />
										</div>
										<div
											class="actions-icon-btn"
											v-if="access && !equipment.archived"
											@click="
												showActionDeleteAlert(action.id)
											"
										>
											<fa icon="archive" />
										</div>
									</div>
								</div>
								<div class="row"></div>
							</div>
						</div>

						<!-- Documents -->
						<div
							class="log-item_container"
							v-if="activeSection === 'documents'"
						>
							<Documents
								:companyId="companyId"
								collection="equipment"
								:assetId="equipment.id"
								:access="access"
								:archived="equipment.archived"
								:equipmentId="equipment.id"
								fileType="doc"
							/>
						</div>

						<!-- Images -->
						<div
							class="log-item_container"
							v-if="activeSection === 'images'"
						>
							<Images
								:companyId="companyId"
								collection="equipment"
								:assetId="equipment.id"
								:access="access"
								:archived="equipment.archived"
								:equipmentId="equipment.id"
								fileType="image"
							/>
						</div>

						<!-- History -->
						<EquipmentLog
							v-if="activeSection === 'itemHistory'"
							:id="equipmentId"
							:companyId="companyId"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Alert before deleting notes -->
	<div class="alert" v-if="deleteNoteAlert" @click="clearDeleteNoteAlert">
		<span v-if="!errMessage">Are you sure you want to delete?</span>
		<span v-else>{{ errMessage }}</span>
		<button class="btn btn-success" @click="clearDeleteNoteAlert">
			Cancel
		</button>
		<button class="btn btn-danger" @click="deleteNote()">Delete</button>
	</div>

	<!-- Alert before deleting actions -->
	<div class="alert" v-if="deleteActionAlert" @click="clearDeleteActionAlert">
		<span v-if="!errMessage">Are you sure you want to delete?</span>
		<span v-else>{{ errMessage }}</span>
		<button class="btn btn-success" @click="clearDeleteActionAlert">
			Cancel
		</button>
		<button class="btn btn-danger" @click="deleteAction()">Delete</button>
	</div>

	<!-- Modal fade -->
	<router-view v-slot="{ Component }">
		<transition name="fade" mode="in-out">
			<component :is="Component" />
		</transition>
	</router-view>
</template>

<script>
import getDocument from "../../composables/getDocument";
import { projectFirestore, timestampFromDate } from "../../firebase/config";
import { computed, ref, onMounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import UpdateEquipmentForm from "../../components/equipment/UpdateEquipmentForm";
import AddEditEuipmentNote from "../../components/equipment/AddEditEquipmentNote";
import AddEditEquipmentAction from "../../components/equipment/AddEditEquipmentAction";
import Alert from "../../components/Alert";
import account from "../../composables/account";
import Documents from "../../components/Documents.vue";
import Images from "../../components/Images.vue";
import useDocument from "../../composables/useDocument";
import ChecklistList from "../../components/checklists/ChecklistList.vue";
import { useMeta } from "vue-meta";
import EquipmentLog from "../../components/equipment/EquipmentLog.vue";
import ActionsLoading from "../../components/actions/ActionsLoading.vue";
import moment from "moment";
import PageLoading from "../../components/PageLoading.vue";
import equipmentCollection from "../../composables/equipmentsCollection";
import getCollection from "../../composables/getCollection";
import { watchEffect } from "vue";
import EnvUtils from "../../misc/env";

export default {
	name: "EquipmentDetail",
	components: {
		UpdateEquipmentForm,
		Alert,
		Documents,
		ChecklistList,
		EquipmentLog,
		ActionsLoading,
		PageLoading,
		AddEditEuipmentNote,
		AddEditEquipmentAction,
		Images,
	},
	props: ["id"],
	setup(props) {
		useMeta({ title: "Equipment Details" });
		const { userDoc, companyId } = account();

		const {
			getEquipmentNote,
			deleteEquipmentNote,
			getEquipmentAction,
			deleteEquipmentAction,
			getEmployees,
		} = equipmentCollection();
		const equipmentId = props.id;
		const router = useRouter();

		// const notes = ref([])
		// const actions = ref([])
		const selectedNote = ref(null);
		const selectedAction = ref(null);
		const deleteNoteAlert = ref(false);
		const deleteActionAlert = ref(false);
		const errMessage = ref(null);
		const error = ref(null);

		const detail = ref(true);

		const db = projectFirestore;
		const collectionRef = db.collection("companies").doc(companyId);

		const activityRef = collectionRef.collection("activity");

		const equipmentRef = collectionRef
			.collection("equipment")
			.doc(equipmentId);

		const { error: err, document: equipment } = getDocument(equipmentRef);

		onMounted(() => {
			if (props.id && props.id != "bulk-update") {
				fetchUsers();
			}
		});

		watchEffect(() => {
			if (err.value && props.id) {
				console.log("PROPS.ID: ", props.id);
				if (props.id != "bulk-update") {
					error.value = err.value;
				}
			}
		});

		const notesRefData = collectionRef
			.collection("equipment")
			.doc(equipmentId)
			.collection("Notes")
			.where("isArchived", "==", false);
		// .get();
		const { documents: notesData } = getCollection(notesRefData);

		const activeSection = ref("itemHistory");

		const toggleSection = (section) => {
			activeSection.value = section;
		};
		const usersArray = ref([]);

		const fetchUsers = async () => {
			try {
				const employees = await getEmployees(props.companyId);
				usersArray.value = employees;
			} catch (error) {
				console.error("Error while fetching users", error);
			}
		};

		const getUserName = (ownerId) => {
			const matchedUser = usersArray.value.find(
				(user) => user.id === ownerId
			);
			return matchedUser ? matchedUser.name : "";
		};

		const formatDateTime = (timestamp) => {
			const options = {
				month: "long",
				day: "numeric",
				year: "numeric",
				hour: "numeric",
				minute: "numeric",
				hour12: true,
			};

			if (timestamp && timestamp.toDate) {
				return timestamp.toDate().toLocaleString("en-US", options);
			} else if (
				typeof timestamp === "string" ||
				timestamp instanceof String
			) {
				return new Date(timestamp).toDateString("en-US", options);
			} else {
				return "N/A";
			}
		};

		const notes = computed(() => {
			if (notesData.value) {
				return notesData.value;
			}
		});

		const actionsRefData = db
			.collection("EquipmentAction")
			.where("identifier", "==", equipmentId);
		const { documents: actiondata } = getCollection(actionsRefData);

		const actions = computed(() => {
			if (actiondata.value) {
				return actiondata.value;
			}
		});

		const access = computed(() => {
			if (userDoc.value) {
				return (
					userDoc.value.role == "Admin" ||
					userDoc.value.role == "Contributor"
				);
			}
		});

		// Back to equipment link
		const handleClick = () => {
			router.push({ name: "EquipmentDash" });
		};

		// Show the update form
		const showForm = (id) => {
			router.push({
				name: "EquipmentEdit",
				params: { id: id },
			});
		};

		// Restore an archived item of equipment
		const restore = async () => {
			const date = new Date();
			const lastUpdated = timestampFromDate(date);
			const update = {
				archived: false,
				lastUpdated: lastUpdated,
			};

			await equipmentRef.update(update);
		};

		const showNoteForm = () => {
			router.push({
				name: "AddEditEquipmentNote",
				query: {
					equipmentId: equipmentId,
					id: props.id,
					mode: "Add",
				},
			});
		};
		const showActionForm = () => {
			router.push({
				name: "AddEditEquipmentAction",
				query: {
					equipmentId: props.id,
					equipmentName: equipment.value.name,
					identifier: equipment.value.identifier,
					id: props.id,
					mode: "Add",
				},
			});
		};

		const editNote = (note) => {
			router.push({
				name: "AddEditEquipmentNote",
				query: {
					equipmentId: equipmentId,
					noteId: note.id,
					selectedNote: JSON.stringify(note),
					mode: "Edit",
					equipmentANoteRoute: "EquipmentDetail",
					notesRoute: null,
				},
			});
		};
		const editAction = (action) => {
			router.push({
				name: "AddEditEquipmentAction",
				query: {
					equipmentId: equipmentId,
					actionId: action.id,
					selectedAction: JSON.stringify(action),
					mode: "Edit",
					equipmentActionRoute: "EquipmentDetail",
					actionsRoute: null,
				},
			});
		};

		const clearDeleteNoteAlert = () => {
			errMessage.value = null;
			deleteNoteAlert.value = false;
		};
		const clearDeleteActionAlert = () => {
			errMessage.value = null;
			deleteActionAlert.value = false;
		};

		const showDeleteAlert = (noteId) => {
			selectedNote.value = noteId;
			deleteNoteAlert.value = true;
		};

		const showActionDeleteAlert = (actionId) => {
			selectedAction.value = actionId;
			deleteActionAlert.value = true;
		};

		const getNote = async () => {
			notes.value = await getEquipmentNote(equipmentId, companyId);
		};

		const deleteNote = async () => {
			try {
				await deleteEquipmentNote(
					companyId,
					props.id,
					selectedNote.value,
					true
				);

				const index = notes.value.findIndex(
					(note) => note.id === selectedNote.value
				);
				if (index >= 0) {
					notes.value.splice(index, 1);
				}
			} catch (error) {
				console.error("Error while deleting note", error);
			}
			selectedNote.value = null;
			deleteNoteAlert.value = false;
		};

		const setActionColorClass = (state, status) => {
			if (status != "Complete") {
				if (state === "Critical") {
					return "critical";
				} else if (state === "Time-Critical") {
					return "time-critical";
				} else {
					return "non-critical";
				}
			} else {
				return "complete";
			}
		};

		//get action
		const getAction = async (identifier) => {
			actions.value = await getEquipmentAction(identifier);
		};

		//deleteAction
		const deleteAction = async () => {
			try {
				await deleteEquipmentAction(selectedAction.value);
				const index = actions.value.findIndex(
					(action) => action.id === selectedAction.value
				);
				if (index >= 0) {
					actions.value.splice(index, 1);
				}
			} catch (error) {
				console.error("Error while deleting action", error);
			}
			selectedAction.value = null;
			deleteActionAlert.value = false;
		};

		const viewChecklists = ref(false);

		const showChecklists = () => {
			if (viewChecklists.value) {
				viewChecklists.value = false;
			} else {
				viewChecklists.value = true;
			}
		};

		const updating = ref(false);
		const success = ref("");
		const unArchive = async () => {
			updating.value = true;

			let archiveUpdate = {
				archived: false,
			};
			const { updateDoc, error } = useDocument(equipmentRef);
			await updateDoc(archiveUpdate);

			if (!error.value) {
				updating.value = false;
				success.value = "Reactivation Successful!";
				router.push({
					name: "EquipmentDash",
				});
			}
		};

		const isExpanded = ref(false);

		const expandHistory = () => {
			if (isExpanded.value == false) {
				isExpanded.value = true;
			} else {
				isExpanded.value = false;
			}
		};

		// Download excel
		const downloading = ref(false);

		const downloadExcel = async () => {
			downloading.value = true;
			errMessage.value = null;
			const collectionPath = activityRef.path;
			const queryConditions = JSON.stringify([
				{ fieldPath: "tags", opStr: "array-contains", value: props.id },
			]);
			const order = JSON.stringify({
				field: "eventTimestamp",
				directionStr: "desc",
			});

			const apiUrl = `${EnvUtils.FIREBASE_API_URL}generateExcel?collectionPath=${collectionPath}&queryConditions=${queryConditions}&order=${order}`;

			console.log("url:", apiUrl);

			try {
				const response = await fetch(apiUrl);
				const blob = await response.blob();

				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.style.display = "none";
				a.href = url;
				a.download = `${props.id}-HISTORY.xlsx`;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				downloading.value = false;
			} catch (err) {
				console.error("Error:", err);
				errMessage.value = "Excel download failed. Contact us.";
				downloading.value = false;
			}
			downloading.value = false;
		};

		return {
			error,
			equipment,
			handleClick,
			showForm,
			detail,
			access,
			companyId,
			updating,
			success,
			unArchive,
			equipmentId,
			expandHistory,
			isExpanded,
			moment,
			showChecklists,
			viewChecklists,
			showNoteForm,
			showActionForm,
			notes,
			getNote,
			selectedNote,
			editNote,
			deleteNote,
			deleteNoteAlert,
			errMessage,
			clearDeleteNoteAlert,
			showDeleteAlert,
			actions,
			selectedAction,
			editAction,
			deleteAction,
			showActionDeleteAlert,
			deleteActionAlert,
			clearDeleteActionAlert,
			formatDateTime,
			activeSection,
			toggleSection,
			getUserName,
			setActionColorClass,
			downloading,
			downloadExcel,
			restore,
		};
	},
};
</script>

<style lang="scss" scoped>
.btn-large {
	margin: 0 0 0 1em;
	font-size: 1.1em;
}
.right-content {
	span {
		//font-size: 16px !important;

		@include respond(small) {
			//    font-size: 18px !important;
		}
	}
}

.page-container {
	padding: 1em;
	@include respond(large) {
		padding: 1em 0 1em 1em;
	}
}
.item-container {
	margin: 0 1em;
	width: 50vw;
}
.collection-title {
	width: calc(100% - 15px);
	font-size: 14px;
	@include respond(large) {
		padding: 0 0 0.8em 1em;
		display: block;
		text-align: center;
	}

	span {
		&:hover {
			cursor: pointer;
			color: $rm-pink;
		}
	}
}

.table-row-shimmer {
	height: 100px;
	border-radius: 0.5em;
	width: 100%;
}

.lines {
	height: 1.2em;
	width: 100px;
	border-radius: 6px;
	margin-left: 1em;
}

.avatar-inline-left {
	margin: 0 1.7em 0 0.5em;
}

.pill-container {
	background-color: #f1f1f1;
	flex-wrap: wrap;
	max-height: 210px;
	overflow: auto;
	margin: 1.2em 0 0 0;
	padding: 0.8em 0.8em 100px;
	border-radius: 1em;
	box-shadow: inset 0px 0px 15px rgba(39, 55, 65, 0.05);
	.pill {
		background-color: $rm-blue;
		color: white;
		font-size: 0.9em;
		padding: 4px 8px;
		border-radius: 1em;
	}
}

.unArchive {
	width: 100%;
	text-align: center;
}

.icon {
	margin-right: 0.5em;
}

.tile {
	box-shadow: $box-shadow;
}

.rounded {
	border-radius: 1.2em;
}
.background-danger {
	color: white;
	background-color: $rm-danger;
}
.page-content {
	left: 0 !important;
	top: 140px !important;

	@include respond(medium) {
		left: $nav-width !important;
		top: 70px !important;
	}

	@include respond(large) {
		overflow: hidden !important;
	}
}

.split {
	flex-direction: column !important;

	@include respond(small) {
		flex-direction: row !important;
	}
}

.item {
	display: flex;
	align-items: center;

	.item-icon {
		width: 50px;
		text-align: center;
	}
}

table {
	width: 100%;
}

.add-new-item {
	width: 100%;
	text-align: right;
}

.action-icon {
	min-width: 20px;
	width: 20px;
	height: 20px;
	font-size: 0.7em;
}

.critical {
	background-color: $rm-danger;
}

.time-critical {
	background-color: $rm-warning;
}

.non-critical {
	background-color: $rm-dark-blue;
}

.complete {
	background-color: $rm-success;
	font-size: 0.8em;
	padding-top: 2px;
}

.actions-icon-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 25px;
	height: 25px;
	margin-left: 8px;
	border-radius: 50%;
	//border: 2px solid #f1f1f1;
	//background-color: #f1f1f1;
	color: #c1c1c1;
	cursor: pointer;
}

.notes {
	display: none;
}
tr.noteContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.row-flex {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.hideNote {
	display: none;
}
.add-icon-link {
	// position: relative;
	// float: right;
	position: absolute;
	transform: translate(-100%, 50%);
	right: 0%;
}
.note-data {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}
.edit-delete-btn {
	display: flex;
}
.action-container {
	.log-item_tile {
		padding: 0.5em 1em;
	}
	.message {
		font-size: 0.8em;
		color: #616161;
	}
	.action-data {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
.page-links {
	display: flex;
	justify-content: space-between;
	gap: 1em;
	padding: 12px 0.5em;
}
.search-checklist {
	position: absolute;
	transform: translate(38%, -70%);
}
.log-item_container_doc_img {
	display: flex;
	width: calc(100% - 15px);
	flex-direction: column;
	gap: 1em;
	background-color: $rm-light-grey;
	//  padding: 1em 3em 100px;
	padding: 1em 2em 50px;
	min-height: calc(100vh - 110px);
	box-shadow: inset 0px 0px 15px #2737410d;
	border-radius: 5px 0 0 0;
	margin: 0 0 0 1em;

	@include respond(large) {
		padding: 0.5em 3em 100px;
	}
}
.flex h2 {
	font-size: 16px;

	@include respond(small) {
		font-size: 18px;
	}
	@include respond(large) {
		font-size: 22px;
	}
}
</style>
