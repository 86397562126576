<template>
	<Alert :message="alertMessage" v-if="alertMessage" />
	<div class="log-item_container" v-if="!history">
		<div class="loading-container">
			<div class="lines shine"></div>
			<div class="lines shine"></div>
			<div class="lines shine"></div>
		</div>
	</div>

	<div class="log-item_container" v-else>
		<div v-for="log in history" :key="log.id">
			<div class="row log-item_tile">
				<!-- header same for all logs -->
				<div class="user-header">
					<p>
						{{ log.updatedBy || getPerson(log.updatedById) }} on
						{{
							moment(log.eventTimestamp.toDate()).format(
								"MMMM Do YYYY, h:mm a"
							)
						}}:
					</p>
				</div>

				<!-- Checklist -->
				<div
					class="message"
					v-if="
						log.event == 'checklistCompleted' ||
						log.event == 'checklistArchived' ||
						log.event == 'checklistRestored'
					"
				>
					<div class="checklist">
						<span class="check-message">
							<fa class="icon" icon="tasks" />
							<p v-if="log.event == 'checklistCompleted'">
								This item has been attached to a checklist:
								<br />
								<b>{{ log.title }}</b>
							</p>
							<p v-if="log.event == 'checklistArchived'">
								<b>{{ log.title }}</b> has been archived
							</p>
							<p v-if="log.event == 'checklistRestored'">
								<b>{{ log.title }}</b> was archived but has now
								been restored
							</p>
						</span>
						<fa
							class="icon btn btn-icon"
							icon="arrow-right"
							@click="viewChecklist(log.checklistId)"
							v-if="log.event != 'checklistArchived'"
						/>
					</div>
				</div>

				<!-- Message required for all events -->
				<div class="message" v-if="log.message">
					<div>
						<p>
							{{ log.message }}
						</p>
						<p
							v-if="
								log.storageItem &&
								log.storageItem.fileType === 'doc'
							"
							style="margin-top: 5px"
						>
							<b>{{ log.storageItem.fileName }}</b>
						</p>
					</div>

					<!-- Image and document logs -->
					<div v-if="log.storageItem">
						<!-- Images event -->
						<div v-if="log.storageItem.fileType === 'image'">
							<div
								class="actions-icon-btn image-preview"
								@click="openAttachment(log.storageItem.id)"
							>
								<img
									:src="log.storageItem.downloadUrl"
									alt="Image Preview"
								/>
							</div>
						</div>

						<!-- Documents -->
						<div v-if="log.storageItem.fileType === 'doc'">
							<div
								class="actions-icon-btn icons"
								@click="openAttachment(log.storageItem.id)"
							>
								<fa :icon="getIcon(log.storageItem.fileName)" />
							</div>
						</div>
					</div>
				</div>

				<!-- Status changed for equipment -->
				<div
					class="status-icons"
					v-if="
						log.valueChanged == 'checkedStatus' &&
						log.statusBefore &&
						log.statusAfter
					"
				>
					<div
						class="status-icon question"
						v-if="log.statusBefore == 'Unchecked'"
					>
						<fa icon="question-circle" />
					</div>
					<div
						class="status-icon question"
						v-if="log.statusBefore == 'Missing'"
					>
						<fa icon="search" />
					</div>
					<div
						class="status-icon success"
						v-if="log.statusBefore == 'Safe for use'"
					>
						<fa icon="check-circle" />
					</div>
					<div
						class="status-icon warning"
						v-if="log.statusBefore == 'Time-critical issue'"
					>
						<fa icon="flag" />
					</div>
					<div
						class="status-icon danger"
						v-if="log.statusBefore == 'Do not use'"
					>
						<fa icon="exclamation-triangle" />
					</div>
					<fa icon="arrow-right" class="arrow" />
					<div
						class="status-icon question"
						v-if="log.statusAfter == 'Unchecked'"
					>
						<fa icon="question-circle" />
					</div>
					<div
						class="status-icon question"
						v-if="log.statusAfter == 'Missing'"
					>
						<fa icon="search" />
					</div>
					<div
						class="status-icon success"
						v-if="log.statusAfter == 'Safe for use'"
					>
						<fa icon="check-circle" />
					</div>
					<div
						class="status-icon warning"
						v-if="log.statusAfter == 'Time-critical issue'"
					>
						<fa icon="flag" />
					</div>
					<div
						class="status-icon danger"
						v-if="log.statusAfter == 'Do not use'"
					>
						<fa icon="exclamation-triangle" />
					</div>
				</div>

				<!-- Equipment tags -->
				<div class="tags-content-container">
					<div v-if="log.tagsAdded && log.tagsAdded.length > 0">
						<div class="message">
							<p>Tags added:</p>
						</div>

						<div class="content">
							<div class="tags">
								<div v-for="tag in log.tagsAdded" :key="tag">
									<div class="pill">{{ tag }}</div>
								</div>
							</div>
						</div>
					</div>

					<div v-if="log.tagsRemoved && log.tagsRemoved.length > 0">
						<div class="message">
							<p>Tags removed:</p>
						</div>
						<div class="content">
							<div class="tags">
								<div v-for="tag in log.tagsRemoved" :key="tag">
									<div class="pill">{{ tag }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Equipment notes -->
				<div
					class="tags-content-container"
					v-if="log.notesText || log.beforeText"
				>
					<div v-if="log.beforeText">
						<div class="message">
							<p>Notes changed from:</p>
						</div>
						<div v-if="log.beforeText" class="content">
							<p>
								<i>"{{ log.beforeText }}"</i>
							</p>
						</div>
					</div>
					<div v-if="log.notesText">
						<div class="message">
							<p v-if="log.beforeText">To:</p>
						</div>
						<div v-if="log.notesText" class="content">
							<p>
								<i>"{{ log.notesText }}"</i>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Alert before deleting notes -->
	<div class="alert" v-if="deleteNoteAlert" @click="clearDeleteNoteAlert">
		<span v-if="!errMessage">Are you sure you want to delete?</span>
		<span v-else>{{ errMessage }}</span>
		<button class="btn btn-success" @click="clearDeleteNoteAlert">
			Cancel
		</button>
		<button class="btn btn-danger" @click="deleteNote">Delete</button>
	</div>

	<!-- Alert before deleting actions -->
	<div class="alert" v-if="deleteActionAlert" @click="clearDeleteActionAlert">
		<span v-if="!errMessage">Are you sure you want to delete?</span>
		<span v-else>{{ errMessage }}</span>
		<button class="btn btn-success" @click="clearDeleteActionAlert">
			Cancel
		</button>
		<button class="btn btn-danger" @click="deleteAction">Delete</button>
	</div>

	<!-- Modal popup -->
	<div class="modal" v-if="showModal && selectedImage">
		<div class="modal-container">
			<div class="modal-image">
				<div class="close">
					<div class="icon" @click="closeImageModal">&times;</div>
				</div>

				<img :src="selectedImage.downloadUrl" alt="Image" />
			</div>
		</div>
	</div>
</template>

<script>
import getCollection from "../../composables/getCollection";
import { projectFirestore } from "../../firebase/config";
import moment from "moment";
import { useRouter } from "vue-router";
import ListLoading from "../ListLoading.vue";
import Alert from "../../components/Alert";
import PageLoading from "../../components/PageLoading.vue";
import { ref } from "@vue/runtime-core";
import equipmentCollection from "../../composables/equipmentsCollection";
import {
	faFilePdf,
	faFileWord,
	faFileExcel,
	faFilePowerpoint,
	faFileAlt,
	faFileCsv,
} from "@fortawesome/free-solid-svg-icons";
import employeeService from "../../composables/employeeService";
import { onMounted } from "@vue/runtime-core";
import account from "../../composables/account";
export default {
	components: { ListLoading, Alert, PageLoading },
	name: "EquipmentLog",
	props: ["id", "companyId", "access"],
	setup(props) {
		const router = useRouter();
		const access = props.access;
		const { deleteEquipmentNote, deleteEquipmentAction } =
			equipmentCollection();
		const { fetchEmployeeNames, fetchInspector } = employeeService();
		const { companyId } = account();
		const db = projectFirestore;
		const collectionRef = db.collection("companies").doc(props.companyId);

		const activityRef = collectionRef.collection("activity");

		const historyRef = activityRef
			.where("tags", "array-contains", props.id)
			.orderBy("eventTimestamp", "desc");

		const { documents: history } = getCollection(historyRef);
		const ownerEmployees = ref([]);
		const inspectorEmployees = ref([]);
		const viewChecklist = (id) => {
			let routeData = router.resolve({
				name: "ChecklistDetail",
				params: { id: id },
			});
			window.open(routeData.href, "_blank");
		};
		const getPerson = (id) => {
			const employee = ownerEmployees.value.find((emp) => emp.id === id);

			if (employee) {
				return employee.name;
			} else {
				const inspector = inspectorEmployees.value.find(
					(emp) => emp.id === id
				);
				if (inspector) {
					return inspector.name;
				} else {
					return "";
				}
			}
		};
		onMounted(async () => {
			const ownerEmployeeNames = await fetchEmployeeNames(companyId);

			ownerEmployeeNames.docs.forEach((doc) => {
				const employeeId = doc.id;
				const employeeFullName = doc.data().profile.fullName;
				ownerEmployees.value.push({
					id: employeeId,
					name: employeeFullName,
				});
			});

			const inspectorNames = await fetchInspector(companyId);

			inspectorNames.forEach((doc) => {
				const inspectorId = doc.id;
				const inspectorName = doc.name;

				inspectorEmployees.value.push({
					id: inspectorId,
					name: inspectorName,
				});
			});
		});
		const notesRef = collectionRef
			.collection("equipment")
			.doc(props.id)
			.collection("Notes");
		const { documents: notes } = getCollection(notesRef);

		const actionsRefData = projectFirestore
			.collection("EquipmentAction")
			.where("identifier", "==", props.id);
		const { documents: actiondata } = getCollection(actionsRefData);

		const companyRef = db.collection("companies").doc(props.companyId);
		const storageRef = companyRef
			.collection("equipment")
			.doc(props.id)
			.collection("storage");

		const { documents: storage } = getCollection(storageRef);

		const alertMessage = ref("");
		const deleteNoteAlert = ref(false);
		const deleteActionAlert = ref(false);
		const errMessage = ref(null);
		const selectedDeleteNoteId = ref(null);
		const selectedDeleteActionId = ref(null);
		const selectedImage = ref(null);
		const showModal = ref(false);

		const editNote = (noteItemId) => {
			const selectedNote = notes.value.find(
				(note) => note.id === noteItemId
			);

			if (selectedNote && !selectedNote.isArchived) {
				router.push({
					name: "AddEditEuipmentNote",
					query: {
						equipmentId: props.id,
						noteId: noteItemId,
						selectedNote: JSON.stringify(selectedNote),
						mode: "Edit",
						equipmentANoteRoute: "EquipmentDetail",
						notesRoute: null,
					},
				});
				alertMessage.value = "";
			} else {
				alertMessage.value = "This note has already been deleted.";
			}
		};

		const editAction = (logId) => {
			const selectedAction = actiondata.value.find(
				(action) => action.id === logId
			);
			if (selectedAction) {
				router.push({
					name: "AddEditEquipmentAction",
					query: {
						equipmentId: props.id,
						actionId: logId,
						selectedAction: JSON.stringify(selectedAction),
						mode: "Edit",
						equipmentActionRoute: "EquipmentDetail",
						actionsRoute: null,
					},
				});
				alertMessage.value = "";
			} else {
				alertMessage.value = "This action has already been deleted.";
			}
		};

		const deleteNote = async () => {
			const noteItemId = selectedDeleteNoteId.value;
			const selectedNote = notes.value.find(
				(note) => note.id === noteItemId
			);

			if (selectedNote) {
				try {
					await deleteEquipmentNote(
						props.companyId,
						props.id,
						noteItemId,
						true
					);
				} catch (error) {
					console.error("Error while deleting note", error);
				}
			} else {
				alertMessage.value = "This note has already been deleted.";
			}
		};

		const deleteAction = async () => {
			const logId = selectedDeleteActionId.value;

			const selectedAction = actiondata.value.find(
				(action) => action.id === logId
			);

			if (selectedAction) {
				try {
					await deleteEquipmentAction(logId);
				} catch (error) {
					console.error("Error while deleting action", error);
				}
			} else {
				alertMessage.value = "This action has already been deleted.";
			}
		};

		const clearDeleteNoteAlert = () => {
			errMessage.value = null;
			deleteNoteAlert.value = false;
		};
		const clearDeleteActionAlert = () => {
			errMessage.value = null;
			deleteActionAlert.value = false;
		};

		const showDeleteAlert = (noteItemId) => {
			console.log(noteItemId, "id");
			const selectedNote = notes.value.find(
				(note) => note.id === noteItemId
			);

			if (selectedNote && selectedNote.isArchived) {
				alertMessage.value = "This note has already been deleted.";
			} else {
				deleteNoteAlert.value = true;
				selectedDeleteNoteId.value = noteItemId;
			}
		};

		const showActionDeleteAlert = (logId) => {
			const selectedAction = actiondata.value.find(
				(action) => action.id === logId
			);

			if (selectedAction) {
				deleteActionAlert.value = true;
				selectedDeleteActionId.value = logId;
			} else {
				alertMessage.value = "This action has already been deleted.";
			}
		};

		const openAttachment = (logId) => {
			const image = storage.value.find((img) => img.id === logId);

			if (image) {
				if (image.fileType === "image") {
					selectedImage.value = image;
					showModal.value = true;
				} else if (image.fileType === "doc") {
					const newTab = window.open(image.downloadUrl, "_blank");
					if (newTab) {
						newTab.focus();
					}
				}
				// if (!image.isArchived) {
				// }
				// else {
				// 	if (image.fileType === "image") {
				// 		alertMessage.value =
				// 			"This image has already been deleted.";
				// 	} else if (image.fileType === "doc") {
				// 		alertMessage.value =
				// 			"This document has already been deleted.";
				// 	}
				// }
			} else {
				alertMessage.value = "The requested log does not exist.";
			}
		};

		const closeImageModal = () => {
			selectedImage.value = null;
			showModal.value = false;
			console.log("Image Modal Closed");
		};

		const getIcon = (fileName) => {
			const fileExtension = fileName
				.substr(fileName.lastIndexOf(".") + 1)
				.toLowerCase();

			const iconMap = {
				csv: faFileCsv,
				pdf: faFilePdf,
				doc: faFileWord,
				docx: faFileWord,
				xls: faFileExcel,
				xlsx: faFileExcel,
				ppt: faFilePowerpoint,
				pptx: faFilePowerpoint,
				txt: faFileAlt,
			};

			return iconMap[fileExtension] || null;
		};

		return {
			history,
			moment,
			viewChecklist,
			editNote,
			deleteNote,
			alertMessage,
			clearDeleteNoteAlert,
			deleteNoteAlert,
			errMessage,
			showDeleteAlert,
			selectedDeleteNoteId,
			editAction,
			showActionDeleteAlert,
			clearDeleteActionAlert,
			deleteAction,
			selectedDeleteActionId,
			deleteActionAlert,
			openAttachment,
			closeImageModal,
			showModal,
			selectedImage,
			getIcon,
			getPerson,
			access,
		};
	},
};
</script>

<style lang="scss" scoped>
.pill {
	background-color: $rm-blue;
	color: white;
}
.message {
	display: flex;
	justify-content: space-between;
}
.edit-delete-btn {
	display: flex;
	gap: 1em;
	color: #c1c1c1;
}
.actions-icon-btn {
	&:hover {
		cursor: pointer;
		color: $rm-pink;
	}
}
// .modal {
// 	position: fixed;
// 	// top: 50%;
// 	// left: 50%;
// 	// transform: translate(-50%, -50%);
// 	width: 100%;
// 	height: 100%;
// 	background-color: white;
// 	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
// }

.modal-image {
	position: relative;
	img {
		width: 100%;
		max-width: 90vw;
		max-height: 90vh;
		border-radius: $border-radius;
		box-shadow: $box-shadow;
	}
	.close {
		position: absolute;
		right: 20px;
		top: 15px;
		font-size: 20px;
		font-weight: bold;
		background-color: white;
		color: $rm-dark-blue;
		cursor: pointer;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		text-align: center;
		padding: 0px;
		.icon {
			margin-top: -4px;
		}
		&:hover {
			color: $rm-pink;
		}
	}
}

.image-preview {
	img {
		width: 50px;
		height: 50px;
		cursor: pointer;
	}
}
.icons {
	font-size: 20px;
	&:hover {
		cursor: pointer;
		color: $rm-pink;
	}
}
.upload-container {
	display: flex;
	width: 100%;
	justify-content: flex-end;
}

.loading {
	padding: 1em;
}
</style>
